<template>
  <ul class="product-features">
    <li
      v-for="(feature, index) in features"
      :key="`${feature.label}-${index}`"
      class="product-features__feature"
    >
      <IconBase
        v-if="getIcon(feature)"
        class="product-features__icon"
        :params="{ width: 24, height: 24, viewBox: '0 0 24 24' }"
        fill="none"
      >
        <component :is="getIcon(feature)" />
      </IconBase>

      <BaseText class="product-features__label">{{ feature.label }}</BaseText>
    </li>
  </ul>
</template>

<script>
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'

const IxIconBeaker = () => import('@/components/icons/IxIconBeaker.vue')
const IxIconBoxAlt = () => import('@/components/icons/IxIconBoxAlt.vue')
const IxIconBuilding = () => import('@/components/icons/IxIconBuilding.vue')
const IxIconDocumentAlt = () => import('@/components/icons/IxIconDocumentAlt.vue')
const IxIconFinger = () => import('@/components/icons/IxIconFinger.vue')
const IxIconHomeAlt = () => import('@/components/icons/IxIconHomeAlt.vue')
const IxIconPerson = () => import('@/components/icons/IxIconPerson.vue')
const IxIconStethoscopeAlt = () => import('@/components/icons/IxIconStethoscopeAlt.vue')
const IxIconSyringe = () => import('@/components/icons/IxIconSyringe.vue')

export default {
  name: 'ProductFeatures',

  components: { IconBase, IxIconBuilding },

  props: {
    features: {
      type: Array,
      required: true
    }
  },

  methods: {
    getIcon(feature) {
      switch (feature.icon) {
        case 'beaker':
          return IxIconBeaker

        case 'box':
          return IxIconBoxAlt

        case 'building':
          return IxIconBuilding

        case 'document':
          return IxIconDocumentAlt

        case 'finger':
          return IxIconFinger

        case 'home':
          return IxIconHomeAlt

        case 'person':
          return IxIconPerson

        case 'stethoscope':
          return IxIconStethoscopeAlt

        case 'syringe':
          return IxIconSyringe
      }

      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.product-features {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-block-end: 0;
  padding-inline-start: 0;
  list-style: none;

  &__feature {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__icon {
    align-self: flex-start;
  }

  &__label {
    font-size: $pharma-font-size-text-sm;
    font-weight: 500;
  }
}
</style>
