import Vue from 'vue'
import Router from 'vue-router'
import { Trans } from '@/plugins/Translation.js'
import storage from '@/classes/Storage.js'
import { store } from '@/store/index.js'
import { FETCH_PRODUCT_LIST } from '@/store/products.js'
import { AUTH_LOGOUT, PROFILE_REQUEST, PROFILE_STATUS } from '@/constants/profile.js'
import { GET_FEATURE_FLAGS } from '@/store/feature.js'
import { GET_CONFIGS } from '@/store/config.js'
import { CONFIG } from '@/constants/config.js'
import { getConfig } from '@/use/useConfig.js'
import { FETCH_INTEGRATION_SETTINGS } from '@/store/integrationSetting.js'

import { isEmpty } from 'lodash-es'

// child Routes
import mainRoutes from './main.js'
import kitRegistrationRoutes from './kitRegistration.js'
import patientRoutes from './patient.js'
import authProxyRoutes from './authProxyRoutes.js'
import wizardRoutes from './selfTestReportWizard.js'
import redirectRoutes from './redirect.js'
import eligibilityRoutes, { eligibilityWelcomePage } from './eligibility.js'
import selectTestRoutes from './selectTest.js'
import { KIT_ACTIVATION } from '@/constants/kitActivation.js'
import { CHECK_CONNECTION } from '@/store/notifications.js'
import { trackPageView } from '@/utils/analytics/pageview.js'
import { getFallbackPath, isAuthenticated } from '@/utils/routeUtils.js'
import { getGlobalParameters } from '@/utils/analytics/events.js'
import { useDarioHealth } from '@/use/useDarioHealth.js'

Vue.use(Router)

const redirectRoutesData = redirectRoutes

const createRoutes = () => {
  const routes = [
    {
      path: '/:lang',
      component: {
        render: (c) => c('router-view')
      },
      beforeEnter: Trans.routeMiddleware,
      children: [
        { ...mainRoutes },
        { ...kitRegistrationRoutes },
        { ...patientRoutes },
        { ...wizardRoutes },
        { ...eligibilityRoutes },
        { ...eligibilityWelcomePage },
        ...selectTestRoutes,
        ...authProxyRoutes
      ]
    },
    {
      path: '*',
      redirect: () => `/${Trans.defaultLanguage}/${getFallbackPath()}`
    }
  ]
  if (redirectRoutesData.length) {
    routes.unshift(...redirectRoutesData)
  }
  return routes
}

const router = new Router({
  mode: 'history',
  routes: createRoutes(),
  scrollBehavior(to) {
    if (to.params.selector) {
      return {
        selector: to.params.selector,
        offset: { y: to.params.offset ?? 0 },
        behavior: 'smooth'
      }
    }

    if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

function nextToLogin(next, to) {
  const lang = to.params.lang || Trans.getUserSupportedLang()
  return next({
    name: 'Login',
    params: { lang },
    query: { redirect_to: to.path, email: to.query.email || to.query.login_hint }
  })
}

// Taking care of loading productList for signed in and signed out users, also making
// sure we have the profile data in place on the screens where the user must be logged in
router.beforeEach(async (to, from, next) => {
  try {
    const promises = []

    if (isEmpty(store.getters.featureFlags) && !store.getters.featureFlagsRequestFailed) {
      promises.push(store.dispatch(GET_FEATURE_FLAGS))
    }
    if (isEmpty(store.getters.configs) && !store.getters.configsRequestFailed) {
      promises.push(store.dispatch(GET_CONFIGS))
    }

    if (
      isEmpty(store.getters.integrationSettings) &&
      !store.getters.integrationSettingsRequestFailed
    ) {
      promises.push(store.dispatch(FETCH_INTEGRATION_SETTINGS))
    }

    await Promise.all(promises)

    if (
      to.matched.some(
        (route) =>
          (route.meta.requiresAuth && !route.meta.skipSessionPopup) ||
          (store.getters.isAuthenticated && route.meta.requireProfileWhenAuthenticated)
      ) &&
      !store.getters.profile
    ) {
      // Depends on flags call and therefore cannot be parallelized
      await Promise.all([store.dispatch(PROFILE_REQUEST), store.dispatch(PROFILE_STATUS)])
    }

    if (!store.getters.productList) {
      let organizationId = getConfig(CONFIG.ORGANIZATION) || null
      if (store.getters.profile) {
        organizationId = store.getters.profile?.organization?.id
      }

      // Depends on config call and therefore cannot be parallelized
      await store.dispatch(FETCH_PRODUCT_LIST, to.name === 'VerifyEmail' ? null : organizationId)
    }

    return next()
  } catch (e) {
    // Problem with loading my own profile data - logout and go to login
    try {
      // No point trying to log the user out if the backend is unavailable
      if (store.getters.hasConnectionError || store.getters.isInvalidReferrer) return next()
      await store.dispatch(AUTH_LOGOUT)
    } catch (e) {
      // eslint-disable-line
      return next()
    }
    return nextToLogin(next, to)
  }
})

router.beforeEach((to, from, next) => {
  const signupProfileUrl = `/${Trans.getUserSupportedLang()}/signup-profile`
  const isProfileComplete = () => storage.getStoredItem('isProfileComplete') === 'true'
  const hasUrlChangedOn = (toNames) => toNames.some((name) => to.name === name)
  const hasPageTurnedOff = (pageConfigData) =>
    pageConfigData.some(
      (pageConfigs) => pageConfigs.hasTurnedOff && hasUrlChangedOn([pageConfigs.nameTo])
    )
  const defaultSignedInRouterLinkTo = store.getters.defaultSignedInRouterLinkTo

  if (isAuthenticated() && to.meta?.redirectOnAuthentication) {
    if (from.query && from.query.redirect_to) {
      // if we have a redirect_to parameter on the query string go to that page
      next({ path: from.query.redirect_to })
    } else if (defaultSignedInRouterLinkTo) {
      // go to default dashboard based on product types
      next({
        name: defaultSignedInRouterLinkTo,
        params: { lang: to.params.lang }
      })
    } else {
      next({ name: 'TestDashboard', params: { lang: to.params.lang } })
    }
  } else if (isAuthenticated() && !isProfileComplete() && to.path !== signupProfileUrl) {
    if (to.name === 'Logout' || to.name === 'Legal') {
      next()
    } else {
      /* This check is required for report generation.
       * If a profile is incomplete and the report generator URL is called via the headless browser,
       * the code redirects to the signup profile page causing the report generator to create a pdf
       * of the incomplete profile screen. By adding a param check, we bypass the incomplete profile
       * screen and redirect to report page thereby creating the right PDF.
       * */
      if ((to.name === 'Results' || to.name === 'Report') && to.query['skip_patient_check']) {
        next()
      } else {
        next({ name: 'SignupProfile', params: { lang: to.params.lang } })
      }
    }
  } else if (to.meta?.requiresAuth || (isAuthenticated() && hasUrlChangedOn(['Login']))) {
    // if the next page requires a token and if we dont have a token go to login
    if (isAuthenticated()) {
      if (!from.name && hasUrlChangedOn(['Prescreen', 'Location', 'Login'])) {
        // through prescreen flow, if the user changes the url orgId or refreshes the page we will force to them to start again from /dashboard/{product_type}
        // also redirect user if they try to enter into an unabled page
        next({
          name: defaultSignedInRouterLinkTo,
          params: { lang: to.params.lang }
        })
      } else {
        next()
      }
    } else {
      if (to.meta?.useBeforeEnterWhenSignedOut && to.matched[to.matched.length - 1]?.beforeEnter) {
        return next()
      }

      return nextToLogin(next, to)
    }
  } else if (
    !from.name &&
    hasUrlChangedOn(['PatientPrescreen', 'PatientFinal', 'PatientOrderScheduling'])
  ) {
    // through patient flow, if the user changes the url orgId or refreshes the page we will force to them to start again from /patient
    next({ name: 'OrderTestStart', params: { lang: to.params.lang } })
  } else if (
    !from.name &&
    hasPageTurnedOff([
      {
        hasTurnedOff: !store.getters.featureFlags?.allowUserReg,
        nameTo: 'Register'
      },
      {
        hasTurnedOff: !store.getters.featureFlags?.hasSignedOutPrescreen,
        nameTo: 'PatientStart'
      }
    ])
  ) {
    // redirect the user to the signed out default page if they try to get to a switched off page
    const signedOutFallbackName = getConfig(CONFIG.ROUTER_FALLBACK)?.signedOut?.name
    next({ name: signedOutFallbackName, params: { lang: to.params.lang } })
  } else if (!from.name && hasUrlChangedOn(['DirectPrescreen'])) {
    // through direct (dtc) prescreen flow, if the user changes the url orgId or prodId or refreshes the page we will force to them to start again from
    // company order page
    if (getConfig(CONFIG.COMPANY_ORDER_PAGE_WEB_URL)) {
      window.location.href = getConfig(CONFIG.COMPANY_ORDER_PAGE_WEB_URL)
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  const { kit_id: kitId, product_id: productId } = to.query

  if (kitId) {
    sessionStorage.setItem(KIT_ACTIVATION.KIT_ID, kitId)

    if (productId) {
      sessionStorage.setItem(KIT_ACTIVATION.PRODUCT_ID, productId)
    } else {
      sessionStorage.removeItem(KIT_ACTIVATION.PRODUCT_ID)
    }
  }

  next()
})

router.beforeEach((to, from, next) => {
  const { storeUserInformation } = useDarioHealth()
  storeUserInformation(to)

  next()
})

router.beforeEach((to, from, next) => {
  if (!window.gaTagConfigurations?.length && !window.gtag) {
    return next()
  }

  window.gaTagConfigurations.forEach((config) => {
    window.gtag('config', config.measurementId, getGlobalParameters(to, store, config))
  })

  if (to.meta.hasManualPageViewTracking || to.name === from.name) {
    return next()
  }

  trackPageView(to)

  next()
})

router.onError((error) => {
  if (error.name === 'ChunkLoadError') {
    store.dispatch(CHECK_CONNECTION)
  }
})

export default router
