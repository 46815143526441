<template>
  <Portal to="below-page">
    <PopupV4
      show-close-button
      class="pharma-test-details"
      :single-btn="$t('btn.close')"
      :on-click="() => $emit('close')"
      @close="$emit('close')"
    >
      <template #title>
        <BaseHeading as="h3" variant="semibold">{{ title }}</BaseHeading>
      </template>
      <template #body>
        <div v-if="content.length > 0" ref="content" class="pharma-test-details__content-container">
          <template v-for="(item, index) in content">
            <div
              v-if="item.type === 'image'"
              :key="item.id"
              class="pharma-test-details__image-container"
            >
              <img
                :src="item.value.large.src"
                :alt="item.value.large.alt"
                class="pharma-test-details__image"
              />
            </div>
            <BaseHeading
              v-if="index > 0 && item.type === 'heading'"
              :key="item.id"
              tag="h4"
              as="h5"
              variant="bold"
              class="pharma-test-details__heading"
            >
              {{ item.value }}
            </BaseHeading>
            <div
              v-if="item.type === 'paragraph'"
              :key="item.id"
              class="pharma-test-details__paragraph"
            >
              <BaseText as="div" variant="large-regular" v-html="item.value" />
            </div>
          </template>
        </div>
      </template>
    </PopupV4>
  </Portal>
</template>

<script>
import { Portal } from 'portal-vue'
import PopupV4 from '@/components/ui-components/Popup/PopupV4.vue'

export default {
  name: 'PharmaTestDetailsPopup',

  components: {
    PopupV4,
    Portal
  },

  props: {
    title: {
      type: String,
      required: true
    },

    /* Array of CMS Paragraphs and Images */
    content: {
      type: Array,
      required: true
    }
  },
  async mounted() {
    await this.$nextTick()
    window.setTimeout(() => this.initCollapsibleLists(), 100)
  },
  methods: {
    initCollapsibleLists() {
      const allLists = this.$refs?.content?.querySelectorAll('ul')

      if (!allLists) {
        // No collapsible lists to process
        return
      }

      // Select all <ul> elements within the content reference
      allLists.forEach((ul) => {
        // Get all top-level <li> elements within the current <ul>
        let topLevelItems = ul.children
        topLevelItems = Array.from(topLevelItems).filter(
          (item) => item.tagName === 'LI' && item.querySelector('ul')
        )
        // Add event listeners to each top-level <li>
        topLevelItems.forEach((item) => {
          if (item.tagName === 'LI') {
            // Ensure it's an <li> element
            item.classList.add('collapsible') // Add the collapsible class
            const parentUl = item.parentElement
            if (parentUl && parentUl.tagName === 'UL') {
              parentUl.classList.add('collapsible')
            }
            item.addEventListener('click', function (event) {
              event.stopPropagation() // Prevent event bubbling
              this.classList.toggle('active') // Toggle 'active' class

              // Toggle visibility of nested <ul>
              const nestedUl = this.querySelector('ul')
              if (nestedUl) {
                nestedUl.style.display = nestedUl.style.display === 'block' ? 'none' : 'block'
              }
            })
            // Hide all nested lists by default
            const nestedUl = item.querySelector('ul')
            nestedUl.style.display = 'none'
          }
        })
      })
    }
  }
}
</script>

<style lang="scss">
.pharma-test-details {
  color: $pharma-color-text-primary;

  &__content-container {
    display: flex;
    flex-direction: column;
    gap: $pharma-spacing-4xl;
    padding-block-start: $pharma-spacing-4xl;

    // Add some spacing between paragraphs
    p {
      margin-block-end: $pharma-spacing-md;
    }

    .base-text > ul:not(.collapsible) {
      padding-inline-start: 20px;

      li {
        margin-block-end: 4px;
        list-style-image: url('@/assets/img/pharma/icon-tick.svg');
      }
    }

    // Accordion for collapsible lists
    ul.collapsible {
      padding: 0;

      li.collapsible {
        position: relative;
        list-style: none;
        border-bottom: 1px solid $pharma-color-border-primary;
        padding: 16px 40px 16px 0;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::after {
          content: url('@/assets/img/pharma/faq-chevron-down-v4.svg');
          display: inline;
          position: absolute;
          inset-inline-end: 0;
          height: 24px;
        }

        &:last-child {
          // Remove border from last item
          border-bottom: 0;
        }

        ul {
          list-style: disc;
          padding-inline-start: $pharma-spacing-xl;
          margin-block-start: $pharma-spacing-md;
          overflow-wrap: anywhere;
        }

        &.active {
          &::after {
            transform: rotate(180deg); // Rotate icon when active
            transform-origin: center;
          }
        }
      }
    }
  }

  &__image-container {
    width: 100%;
  }

  &__image {
    width: 100%;
    height: auto;
    border-radius: var(--components-cards-card-radius, $pharma-card-border-radius);
  }

  &__heading {
    margin-block-end: $pharma-spacing-lg;
  }

  &__heading + .pharma-test-details__paragraph {
    margin-block-start: -1 * $pharma-spacing-4xl;
  }
}
</style>
