<template>
  <IconBase
    class="popup__close"
    :params="{
      width: 24,
      height: 24,
      viewBox: '0 0 24 24',
      name: 'icon.close'
    }"
    @click="$emit('close')"
  >
    <IxIconClose />
  </IconBase>
</template>

<script>
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconClose from '@/components/icons/IxIconClose.vue'

export default {
  name: 'PopupCloseButton',

  components: {
    IconBase,
    IxIconClose
  }
}
</script>
