export const CMS_META_FIELDS = [
  'title',
  'html_url',
  'slug',
  'first_published_at',
  'tags',
  'default',
  'org_content_id',
  'body',
  'contenttype',
  'live',
  'has_unpublished_changes',
  'locale',
  'type',
  'detail_url'
]
