<i18n src="./locales/lang.json"></i18n>
<template>
  <footer class="footer">
    <div class="footer__main container">
      <div class="footer__row row">
        <div class="col-12 col-md-4 col-lg-5">
          <PortalTarget name="footer" />

          <img :src="logo" :alt="organizationName" class="footer__logo" />
        </div>

        <div class="col-12 col-md-8 col-lg-7 px-0">
          <NavBarV3
            id="footer"
            :is-hidden="isHidden"
            :is-disabled="isDisabled"
            :is-footer="true"
            :toggle-breakpoint="false"
            :is-login-section="false"
            :link-groups="menuItems"
            class="footer__navbar col-12"
            :aria-label="$t('footer.aria_label')"
          />
        </div>

        <div class="footer__copyright col-12">
          {{ $t('footer.copyright_1') }}
          {{ excludedStates.length ? $t('footer.copyright_2', [excludedStates]) : null }}

          <div v-if="dsarUrl || termlyWebsiteUuid" class="footer__consent-links mt-1">
            <a
              v-if="dsarUrl"
              :href="dsarUrl"
              target="_blank"
              rel="noopener noreferrer"
              class="footer__link v3-anchor"
            >
              {{ $t('footer.your_privacy_choices') }}
            </a>
            <a
              v-if="termlyWebsiteUuid"
              href="#"
              class="footer__link v3-anchor"
              @click="showPreferenceModal"
            >
              {{ $t('footer.consent_preferences') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import common from '@/classes/common.js'
import { CONFIG } from '@/constants/config.js'

import NavBarV3 from '@/components/ui-components/NavBar/NavBarV3.vue'
import { mapGetters } from 'vuex'
import { PortalTarget } from 'portal-vue'

export default {
  name: 'AppFooterV3',

  components: {
    PortalTarget,
    NavBarV3
  },

  props: {
    isHidden: {
      default: false,
      type: Boolean
    },
    isDisabled: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    ...mapGetters(['getThemeValue', 'organizationName', 'getConfig', 'featureFlags']),
    excludedStates() {
      return (this.getConfig(CONFIG.FOOTER_EXCLUDED_STATES) || []).join(', ')
    },
    helpCenterUrl() {
      return this.getConfig(CONFIG.HELP_CENTER_URL)
    },
    dsarUrl() {
      return this.getConfig(CONFIG.DSAR_URL)
    },
    termlyWebsiteUuid() {
      return this.getConfig(CONFIG.TERMLY_WEBSITE_UUID)
    },
    menuItems() {
      const itemsData = this.getConfig(CONFIG.FOOTER_MENU_ITEMS_V3)
      if (!itemsData) return

      const items = itemsData?.map((item) => {
        if (item.title === '{companyName}') {
          return { ...item, title: this.companyName }
        }
        return item
      })
      const menuItems = this.getMenuItems(items)
      if (this.helpCenterUrl)
        menuItems[1]?.links?.push({
          text: this.$t('footer.support_title'),
          href: this.helpCenterUrl
        })
      return menuItems
    },
    logo() {
      return common.cleanCssThemeUrl(
        this.getThemeValue('--images-logo-footer') || this.getThemeValue('--logo-desktop')
      )
    }
  },

  methods: {
    getMenuItems(items) {
      return common.getNavBarMenuItems(items, this.featureFlags.allowUserReg, this.$router)
    },

    showPreferenceModal(event) {
      event.preventDefault()
      window.displayPreferenceModal?.()
    }
  }
}
</script>

<style lang="scss" scoped>
$main-spacing-mobile: 24px;
$main-spacing: 40px;

.footer {
  margin-top: $main-spacing-mobile;

  @include media-breakpoint-up(md) {
    margin-top: $main-spacing;
  }

  &__main {
    padding-top: $main-spacing-mobile;
    padding-bottom: $main-spacing-mobile;

    @include media-breakpoint-up(md) {
      padding-top: $main-spacing;
    }
  }

  &__logo {
    margin-bottom: 24px;
    max-width: 100%;
  }

  &__link {
    text-decoration: none;
  }

  &__copyright {
    margin-top: 14px;
    font-size: 12px;
    color: $footer-copyright-color;
  }

  &__consent-links {
    display: flex;
    gap: 8px;
    line-height: 1;

    > *:not(:first-child) {
      padding-left: 8px;
      border-left: 1px solid $footer-copyright-color;
    }

    a:hover {
      color: $color-grey;
    }
  }
}
</style>
