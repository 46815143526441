<template>
  <BaseBtn
    class="ghost-btn"
    :class="{ 'ghost-btn--active': active }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </BaseBtn>
</template>

<script>
export default {
  name: 'GhostBtn',
  props: {
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.ghost-btn {
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: $v3-primary;
    box-shadow: none;
    border: 0;
  }

  &--active {
    &,
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: $v3-color-white;
      box-shadow: none;
    }
  }

  &[disabled],
  &--disabled {
    color: $v3-color-hit-gray;
  }
}
</style>
