import { CONFIG } from '@/constants/config.js'
import { getConfig } from '@/use/useConfig.js'
import { errorHandler } from '@/utils/errorUtils.js'
import { getNormalizedUrl } from '@/utils/analytics/pageview.js'
import { ANALYTICS_GROUP_DEFAULT } from '@/constants/analytics.js'

export function useDarioHealth() {
  const storeUserInformation = (route) => {
    const { dh_u: userId, dh_loc: location = null } = route.query

    if (!userId) {
      return
    }

    window.sessionStorage.setItem(CONFIG.DARIO_HEALTH, JSON.stringify({ userId, location }))
  }

  const getUserInformation = () => {
    try {
      return JSON.parse(sessionStorage.getItem(CONFIG.DARIO_HEALTH))
    } catch {
      return null
    }
  }

  const trackEvent = (eventName) => {
    const config = getConfig(CONFIG.DARIO_HEALTH)
    if (!config || !(config.baseUri && config.token && config.refererId)) return

    const userInfo = getUserInformation()
    if (!userInfo) return

    const consentState = window.Termly?.getConsentState?.() || {}
    if (!consentState.analytics) return

    window
      .fetch(`${config.baseUri}/partners/external/pixels/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-happifyfamily-authorization': config.token
        },
        signal: AbortSignal.timeout(3000),
        body: JSON.stringify({
          referer_id: config.refererId,
          location: userInfo.location,
          referer_type: 'modality',
          event_name: eventName,
          user_id: userInfo.userId
        })
      })
      .catch((err) => {
        errorHandler({
          sentry: `Dario Health tracking failed due to ${err.response?.data?.message || err}`
        })
      })
  }

  const trackConversion = (route) => {
    const config = getConfig(CONFIG.DARIO_HEALTH)
    if (!config || !config.googleAdsTagId) return

    const userInfo = getUserInformation()
    if (!userInfo) return

    if (!window.gtag) return

    const consentState = window.Termly?.getConsentState?.() || {}
    if (!consentState.advertising) return

    const url = getNormalizedUrl(route)
    if (!url) return

    window.gtag('config', config.googleAdsTagId, {
      send_page_view: false,
      page_location: url.toString(),
      page_path: url.pathname,
      page_title: route.meta?.title,
      groups: ANALYTICS_GROUP_DEFAULT
    })
  }

  return {
    storeUserInformation,
    getUserInformation,
    trackEvent,
    trackConversion
  }
}
