/*
 * Traverse the object recursively and transform it to a new object by renaming the attributes'
 * names or/and optionally applying transformation on the values.
 * */
import { camelCase, isArray, isFunction, isObject, snakeCase, each } from 'lodash-es'

export const transformObject = (json, fieldNameTransformer, valueTransformers, result = {}) => {
  if (!json || (!isArray(json) && !isObject(json))) return json
  for (const [fieldName, field] of Object.entries(json)) {
    const key = fieldNameTransformer(fieldName)

    // Optionally transform the `field` to another `value`.
    // It can even transform an array or an object to something else here.
    // Support wildcard ('*') transform too
    const valueTransform = valueTransformers[key] || valueTransformers['*']
    const value = valueTransform ? valueTransform(field) : field
    if (isArray(value)) {
      result[key] = []
      for (let index = 0; index < value.length; index++) {
        const item = value[index]
        result[key].push(transformObject(item, fieldNameTransformer, valueTransformers))
      }
    } else if (isObject(value) && !isFunction(value)) {
      result[key] = transformObject(value, fieldNameTransformer, valueTransformers)
    } else {
      result[key] = value
    }
  }
  return result
}

/*
 * Transform on object to have each key camelCase.
 * Example:
 * {
 *   date_of_birth: '1999/04/01',
 *   backend_data: 'Some info here'
 * }
 *
 * TO:
 * {
 *   dateOfBirth: {year: 1999, month: 4, days: 1}, // A custom value transformer my applied on values
 *   backendData: 'Some info here'
 * }
 **/
export const transformToCamelCase = (json, valueTransformers = {}, result = {}) =>
  transformObject(json, camelCase, valueTransformers, result)

/*
 * Transform on object to have each key camelCase.
 * Example:
 * {
 *   dateOfBirth: {year: 1999, month: 4, days: 1 }
 *   formData: 'Some info here'
 * }
 *
 * TO:
 * {
 *   date_of_birth: '1999/04/01', // Note, a custom value transformer my applied on the values
 *   form_data: 'Some info here'
 * }
 **/
export const transformToSnakeCase = (json, valueTransformers = {}, result = {}) =>
  transformObject(json, snakeCase, valueTransformers, result)

/*
 * Transform on object to flatten it down to a property named "a.b" (delimiter can be specified)
 * Example:
 * {
 *   dateOfBirth: {year: 1999, month: 4, days: {pre: '6'} }
 *   formData: 'Some info here'
 * }
 *
 * TO:
 * {
 *   date_of_birth.year: 1999',
 *   date_of_birth.month: 1999',
 *   date_of_birth.days.pre: 6',
 *   form_data: 'Some info here'
 * }
 **/
export const flattenObject = (obj, delimiter = '.', prefix = '') => {
  const res = {}

  each(obj, (val, key) => {
    // ensure is JSON key-value map, not array
    if (isObject(val) && !isArray(val)) {
      // union the returned result by concat all keys
      const strip = flattenObject(val, delimiter)
      each(strip, function (v, k) {
        res[prefix + key + delimiter + k] = v
      })
    } else {
      res[prefix + key] = val
    }
  })
  return res
}

const _safeParseValue = (value) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return value
  }
}

export const parseObjectValues = (obj) => {
  return Object.entries(obj).reduce((data, [key, value]) => {
    data[key] = _safeParseValue(value)
    return data
  }, {})
}

export const filterObjectEntries = (obj, callback) => {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => callback(key, value)))
}
