<i18n src="./locales/lang.json"></i18n>

<template>
  <div>
    <div class="container">
      <header class="select-test-resources__header">
        <BaseHeading tag="h1" as="h4" variant="bold" class="select-test-results__heading">
          {{ title }}
        </BaseHeading>
        <BaseText v-if="description" as="p">{{ description }}</BaseText>
      </header>

      <SelectTestResourcesList :page-content="selectTestPageContent" />
    </div>

    <Portal to="below-page">
      <OutboundLinkDisclaimerPopup
        v-show="isOutboundLinkDisclaimerPopupVisible"
        :company-name="companyName"
        selector=".action-card"
        @show="isOutboundLinkDisclaimerPopupVisible = true"
        @close="isOutboundLinkDisclaimerPopupVisible = false"
      />
    </Portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Portal } from 'portal-vue'
import { CONFIG } from '@/constants/config.js'
import OutboundLinkDisclaimerPopup from '@/components/ui-components/OutboundLinkDisclaimerPopup/OutboundLinkDisclaimerPopup.vue'
import SelectTestResourcesList from '@/components/views/SelectTestV4/SelectTestResourcesList.vue'

export default {
  name: 'SelectTestResources',

  components: {
    Portal,
    SelectTestResourcesList,
    OutboundLinkDisclaimerPopup
  },

  data() {
    return {
      isOutboundLinkDisclaimerPopupVisible: false
    }
  },

  computed: {
    ...mapGetters({
      getConfig: 'getConfig'
    }),

    headful() {
      return { title: this.$t('headful_title.select_a_test') }
    },

    selectTestPageContent() {
      return this.getConfig(CONFIG.SELECT_TEST_PAGE_V4_CONTENT)
    },

    title() {
      return this.selectTestPageContent.resources?.title
    },

    description() {
      return this.selectTestPageContent.resources?.description
    },

    companyName() {
      return this.getConfig(CONFIG.COMPANY_NAME)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-test-resources {
  &__page {
    padding: 32px 0;

    @include media-breakpoint-up(md) {
      padding: 80px;
    }
  }

  &__header {
    margin-block-end: $pharma-spacing-4xl;
    color: $pharma-color-text-primary;
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }
}
</style>
