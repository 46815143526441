<i18n src="./locales/lang.json"></i18n>

<template>
  <div>
    <div v-if="isLoading" class="d-flex justify-content-center">
      <BSpinner variant="primary" />
    </div>
    <div v-else class="container">
      <EligibilityQuestionnaire
        ref="questionnaire"
        :questionnaire-data="questionnaireData"
        :org-id="dtcOrganization.id"
        :eligibility-response-uuid="eligibilityResponseUuid"
        :answers="answers"
        :question-id="questionId"
        @init="handleQuestionnaireInit"
        @navigate="handleNavigation"
        @submit="submit"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CONFIG } from '@/constants/config.js'
import { errorHandler } from '@/utils/errorUtils.js'
import EligibilityQuestionnaire from '@/components/views/SelectTestV4/EligibilityQuestionnaire.vue'
import common from '@/classes/common.js'
import {
  FETCH_ELIGIBILITY_RESPONSE,
  SET_ELIGIBILITY_RESPONSE,
  SET_IS_BACK_VISIBLE,
  SET_PROGRESS
} from '@/store/selectTest.js'
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { STORE_NAMESPACE } from '@/constants/store.js'

export default {
  name: 'SelectTestQuestionnaire',

  components: {
    EligibilityQuestionnaire
  },

  props: {
    eligibilityResponseUuid: {
      type: String,
      default: ''
    },

    questionId: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      activeQuestionIndex: 0,
      numberOfQuestions: 0,
      responseSet: null,
      isSubmitInProgress: false,
      isLoading: true
    }
  },

  computed: {
    ...mapGetters({
      getConfig: 'getConfig',
      orgByAccessCode: 'orgByAccessCode',
      testDetails: 'cmsTestDetailsContent'
    }),

    ...mapGetters(STORE_NAMESPACE.SELECT_TEST, {
      questionnaireData: 'questionnaire'
    }),

    headful() {
      return { title: this.$t('headful_title.select_a_test') }
    },

    dtcOrganization() {
      return this.getConfig(CONFIG.DTC_ORGANIZATION)
    },

    selectTestPageContent() {
      return this.getConfig(CONFIG.SELECT_TEST_PAGE_V4_CONTENT)
    },

    isBackVisible() {
      return this.activeQuestionIndex > 0
    },

    insuranceSelection() {
      if (!this.responseSet) return ''

      const insurance = this.responseSet?.find((question) => question.question_id === 'insurance')
      return insurance ? insurance.answer_id : ''
    },

    answers() {
      if (!this.responseSet) return null

      return this.responseSet.reduce((answers, answer) => {
        const value = answer.answer_id || answer.answer_text
        answers[answer.question_id] = value ?? ''

        return answers
      }, {})
    }
  },

  watch: {
    isBackVisible: {
      handler(value) {
        this.setIsBackVisible(value)
      },

      immediate: true
    }
  },

  async created() {
    let uuid = this.eligibilityResponseUuid

    if (this.eligibilityResponseUuid) {
      this.responseSet = await this.fetchEligibilityResponse(this.eligibilityResponseUuid)
    } else {
      let response = await IXLayerAPI.submitEligibilityQuestionnaireResponse(
        this.dtcOrganization.id,
        {
          eligibility_questionnaireresponse_questionresponseset: []
        }
      )

      uuid = response.data.uuid
    }

    if (!this.eligibilityResponseUuid || !this.questionId) {
      return this.$router
        .replace({
          name: 'SelectTestQuestionnaire',
          params: {
            eligibilityResponseUuid: uuid,
            questionId:
              this.questionnaireData.questionnaire_sections[0].section_questions[0].question_id
          }
        })
        .catch(() => {})
    }

    this.isLoading = false
  },

  methods: {
    ...mapActions('selectTest', {
      fetchEligibilityResponse: FETCH_ELIGIBILITY_RESPONSE,
      setEligibilityResponse: SET_ELIGIBILITY_RESPONSE,
      setIsBackVisible: SET_IS_BACK_VISIBLE,
      setProgress: SET_PROGRESS
    }),

    handleQuestionnaireInit({ numberOfQuestions }) {
      // Add one to account for showing the results
      this.numberOfQuestions = numberOfQuestions + 1
      this.setProgress((1 / this.numberOfQuestions) * 100)
    },

    handleNavigation({ index, question, numberOfQuestions }) {
      // Add one to account for showing the results
      this.numberOfQuestions = numberOfQuestions + 1

      if (this.$route.params.questionId !== question.question_id) {
        this.$router.push({ params: { questionId: question.question_id } }).catch(() => {})
      }

      this.activeQuestionIndex = index
      this.setProgress(Math.max(0, (index + 1) / this.numberOfQuestions) * 100)
    },

    async submit({ responseSet, eligibilityResponseUuid }) {
      this.responseSet = responseSet

      try {
        this.isSubmitInProgress = true
        common.scrollToTop()

        await this.setEligibilityResponse({
          uuid: eligibilityResponseUuid,
          responseSet
        })

        this.$router
          .push({ name: 'SelectTestResults', params: { eligibilityResponseUuid } })
          .catch(() => {})
      } catch (err) {
        errorHandler({
          toastedMessage: this.$t('select_test.error_fetching_products'),
          sentryErrMessage: `FAILED DUE TO ${err.response?.data?.message || err}`
        })
      } finally {
        this.isSubmitInProgress = false
      }
    }
  }
}
</script>
