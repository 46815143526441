<i18n src="../locales/lang.json"></i18n>

<template>
  <div class="hcp-form-edit">
    <form :aria-label="$t('aria_label.edit_hcp')">
      <div class="row">
        <BaseHeading as="h3" variant="semibold" class="col-12">
          {{ $t('settings.hcp.personal') }}
        </BaseHeading>
      </div>

      <!-- guardian email, first name, last name -->
      <div class="row">
        <!-- first name -->
        <BaseInput
          id="hcp_first_name"
          v-model="$v.hcpForm.first_name.$model"
          :disabled="isFormDisabled"
          :validations="combinedErrors.firstName"
          :label="$t('form.first_name.label')"
          :placeholder="$t('form.first_name.placeholder')"
          :has-required-indicator="isRequiredForOrder"
          css-for-error="input-error"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          :transition="isSharingEnabled ? 'none' : 'fade-in-top'"
        >
          <template #append-container>
            <HcpHintShareResults v-if="isSharingEnabled && !$v.hcpForm.first_name?.$error" />
          </template>
        </BaseInput>

        <BaseInput
          id="hcp_last_name"
          v-model="$v.hcpForm.last_name.$model"
          :validations="combinedErrors.lastName"
          :label="$t('form.last_name.label')"
          :placeholder="$t('form.last_name.placeholder')"
          :has-required-indicator="isRequiredForOrder"
          css-for-error="input-error"
          :disabled="isFormDisabled"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          :transition="isSharingEnabled ? 'none' : 'fade-in-top'"
        >
          <template #append-container>
            <HcpHintShareResults v-if="isSharingEnabled && !$v.hcpForm.last_name?.$error" />
          </template>
        </BaseInput>

        <BaseInput
          id="hcp_npi"
          v-model="$v.hcpForm.npi.$model"
          :validations="combinedErrors.npi"
          :label="$t('hcp.npi.label')"
          :placeholder="$t('hcp.npi.placeholder')"
          :max-length="10"
          :disabled="isFormDisabled"
          css-for-error="input-error"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          transition="fade-in-top"
        />

        <BaseInput
          id="hcp_practice_name"
          v-model="$v.hcpForm.practice_name.$model"
          :validations="combinedErrors.practiceName"
          :label="$t('hcp.practice_name.label')"
          :placeholder="$t('hcp.practice_name.placeholder')"
          :disabled="isFormDisabled"
          css-for-error="input-error"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          transition="fade-in-top"
        />
      </div>

      <div class="row">
        <BaseHeading as="h3" variant="semibold" class="col-12 mt-3">
          {{ $t('form.address.label') }}
        </BaseHeading>
      </div>

      <div class="row">
        <BaseInput
          id="hcp_address"
          v-model="$v.hcpForm.address.$model"
          :validations="combinedErrors.address"
          :label="$t('form.address.label')"
          :placeholder="$t('form.address.placeholder.address')"
          :disabled="isFormDisabled"
          css-for-error="input-error"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          :transition="isSharingEnabled ? 'none' : 'fade-in-top'"
        >
          <template #append-container>
            <HcpHintShareResults
              v-if="isSharingEnabled && !$v.hcpForm.address.$error"
              :hint="$t('form.hcp.share_results_fax_or_address')"
            />
          </template>
        </BaseInput>

        <BaseInput
          id="hcp_address_2"
          v-model="$v.hcpForm.address_2.$model"
          :validations="combinedErrors.address2"
          :label="
            isSharingEnabled ? $t('settings.hcp.address2_optional') : $t('form.address.label2')
          "
          :placeholder="$t('form.address.placeholder.address2')"
          :disabled="isFormDisabled"
          css-for-error="input-error"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          transition="fade-in-top"
        />

        <BaseInput
          id="hcp_city"
          v-model="$v.hcpForm.city.$model"
          :validations="combinedErrors.city"
          autocomplete="address-level2"
          :label="$t('form.address.float_label.city')"
          :placeholder="$t('form.address.placeholder.city')"
          :disabled="isFormDisabled"
          css-for-error="input-error"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          transition="fade-in-top"
        />

        <InputSelectV3
          id="hcp_state"
          v-model="$v.hcpForm.state.$model"
          :validations="combinedErrors.state"
          :options-data="states"
          :label="$t('form.address.float_label.state')"
          :default-option="$t('form.address.default_option.state')"
          css-for-error="input-error"
          :disabled="isFormDisabled"
          class="col-12 col-md-4 col-lg-3"
          value-by="abbreviation"
          transition="fade-in-top"
        />

        <BaseInput
          id="hcp_zip"
          v-model="$v.hcpForm.zip.$model"
          :validations="combinedErrors.zip"
          autocomplete="postal-code"
          inputmode="numeric"
          :mask="['#####', '#####-####']"
          :is-model-masked="true"
          :label="$t('form.address.float_label.zip')"
          :placeholder="$t('form.address.placeholder.zip')"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          :disabled="isFormDisabled"
          css-for-label="ix-input-v3__label"
          css-for-error="input-error"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          transition="fade-in-top"
        />
      </div>

      <div class="row">
        <BaseHeading as="h3" variant="semibold" class="col-12 mt-3">
          {{ $t('settings.hcp.contact') }}
        </BaseHeading>
      </div>

      <div class="row">
        <BaseInput
          id="hcp_phone"
          v-model="$v.hcpForm.phone.$model"
          :validations="combinedErrors.phone"
          autocomplete="tel"
          inputmode="tel"
          :mask="phone().maskFormat"
          :label="$t('form.phone.label')"
          :placeholder="$t('form.phone.placeholder')"
          :disabled="isFormDisabled"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          css-for-error="input-error"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          transition="fade-in-top"
        />

        <BaseInput
          id="hcp_fax"
          v-model="$v.hcpForm.fax_number.$model"
          :validations="combinedErrors.faxNumber"
          autocomplete="tel"
          inputmode="tel"
          :mask="phone().maskFormat"
          :label="$t('hcp.fax.label')"
          :placeholder="$t('hcp.fax.placeholder')"
          :disabled="isFormDisabled"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          css-for-error="input-error"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          :transition="isSharingEnabled ? 'none' : 'fade-in-top'"
        >
          <template #append-container>
            <HcpHintShareResults
              v-if="isSharingEnabled && !$v.hcpForm.fax_number.$error"
              :hint="$t('form.hcp.share_results_fax_or_address')"
            />
          </template>
        </BaseInput>

        <BaseInput
          id="hcp_direct_email_address"
          v-model="$v.hcpForm.direct_email_address.$model"
          :validations="combinedErrors.directEmailAddress"
          input-type="email"
          inputmode="email"
          :max-length="128"
          autocomplete="email"
          :label="$t('hcp.direct_email_address.label')"
          :placeholder="$t('hcp.direct_email_address.placeholder')"
          :disabled="isFormDisabled"
          :css-for-input="[
            'ix-input-v3__input',
            isFormDisabled ? 'ix-input-v3__input--disabled' : ''
          ]"
          css-for-label="ix-input-v3__label"
          css-for-error="input-error"
          class="ix-input-v3 col-12 col-md-4 col-lg-3"
          transition="fade-in-top"
        />
      </div>

      <div v-if="showMakePrimaryCheckbox" class="row">
        <div class="hcp-form-edit__checkbox-col">
          <BaseCheckbox
            v-model="isPrimary"
            plain
            :disabled="isMakePrimaryCheckboxDisabled"
            :class="{ 'hcp-form-edit__checkbox--disabled': isMakePrimaryCheckboxDisabled }"
            @change="(val) => $emit('update:is-primary-hcp', val)"
          >
            <BaseText as="p" :class="{ 'text-muted': isMakePrimaryCheckboxDisabled }">
              {{ $t('settings.hcp.checkbox.make_default') }}
            </BaseText>
          </BaseCheckbox>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import common from '@/classes/common.js'
import { cloneDeep, isEmpty } from 'lodash-es'
import InputSelectV3 from '@/components/ui-components/InputSelect/InputSelectV3.vue'
import Validation from '@/classes/Validation.js'
import { city, state, zip, phone } from '@/utils/validation.js'

import HCPHintShareResults from '@/components/views/Settings/partials/HCPHintShareResults.vue'
import { getPhone, getPhoneMaskFormat, uploadPhone } from '@/utils/profileUtils.js'
import { useForm } from '@/use/useForm.js'

const validation = new Validation()

export default {
  name: 'HCPFormEdit',

  components: {
    HcpHintShareResults: HCPHintShareResults,
    InputSelectV3
  },

  props: {
    provider: {
      type: Object,
      default: null
    },

    isRequiredForOrder: {
      type: Boolean,
      default: false
    },

    isSharingEnabled: {
      type: Boolean,
      default: false
    },

    isFormDisabled: {
      type: Boolean,
      default: false
    },

    showMakePrimaryCheckbox: {
      type: Boolean,
      default: false
    },

    serverErrors: {
      type: Object,
      default: () => ({})
    }
  },

  setup() {
    const { getCombinedErrors } = useForm()

    return {
      getCombinedErrors
    }
  },

  data() {
    return {
      hcpForm: {
        first_name: '',
        last_name: '',
        npi: '',
        practice_name: '',
        address: '',
        address_2: '',
        country: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        fax_number: '',
        direct_email_address: '',
        isSharingEnabled: this.isSharingEnabled
      },
      states: common.filterStates(),
      isLoading: false,
      isPrimary: false
    }
  },

  validations() {
    return {
      hcpForm: this.hcpValidationObject
    }
  },

  computed: {
    isMakePrimaryCheckboxDisabled() {
      return this.hcpForm.is_primary
    },

    hcpValidationObject() {
      return {
        first_name: validation.healthCarePhysician.firstName,
        last_name: validation.healthCarePhysician.lastName,
        npi: !isEmpty(this.hcpForm.npi) ? validation.healthCarePhysician.npi : {},
        practice_name: validation.healthCarePhysician.practiceName,
        address: validation.healthCarePhysician.address,
        address_2: {},
        country: {},
        city: !isEmpty(this.hcpForm.city) ? city() : validation.healthCarePhysician.city,
        state: !isEmpty(this.hcpForm.state) ? state() : validation.healthCarePhysician.state,
        zip: this.hasAddressStateZip
          ? zip({
              address: {
                city: this.hcpForm.city,
                state: this.hcpForm.state
              }
            })
          : validation.healthCarePhysician.zip,
        phone: !isEmpty(this.hcpForm.phone) ? phone(this.countryCode) : {},
        fax_number: validation.healthCarePhysician.faxNumber,
        direct_email_address: validation.healthCarePhysician.directEmailAddress,
        isSharingEnabled: this.isSharingEnabled
      }
    },

    countryCode() {
      return this.hcpForm.country || undefined
    },

    hasAddressStateZip() {
      return this.hcpForm.city && this.hcpForm.state && this.hcpForm.zip
    },

    combinedErrors() {
      return this.getCombinedErrors(this.inputValidations, this.serverErrors)
    },

    inputValidations() {
      return {
        firstName: [
          {
            constraint: this.$v.hcpForm.first_name.$error && !this.$v.hcpForm.first_name.required,
            message: this.$t('validation.general.required_msg')
          },
          {
            constraint: this.$v.hcpForm.first_name.$error && !this.$v.hcpForm.first_name.maxLength,
            message: this.$t('validation.general.max_length', [64])
          }
        ],
        lastName: [
          {
            constraint: this.$v.hcpForm.last_name.$error && !this.$v.hcpForm.last_name.required,
            message: this.$t('validation.general.required_msg')
          },
          {
            constraint: this.$v.hcpForm.last_name.$error && !this.$v.hcpForm.last_name.maxLength,
            message: this.$t('validation.general.max_length', [64])
          }
        ],
        npi: [
          {
            constraint: this.$v.hcpForm.npi.$error && !this.$v.hcpForm.npi.validNpi,
            message: 'validation.npi.valid'
          }
        ],
        practiceName: [
          {
            constraint:
              this.$v.hcpForm.practice_name.$error && !this.$v.hcpForm.practice_name.maxLength,
            message: this.$t('validation.general.max_length', [64])
          }
        ],
        zip: [
          {
            constraint: this.$v.hcpForm.zip.$error && !this.$v.hcpForm.zip.required,
            message: this.$t('settings.hcp.fax_or_address_required')
          },
          {
            constraint: this.$v.hcpForm.zip.$error && !this.$v.hcpForm.zip.pattern,
            message: 'validation.zip.pattern'
          },
          {
            constraint:
              this.$v.hcpForm.$dirty && this.$v.hcpForm.zip.$error && !this.$v.hcpForm.zip.isValid,
            message: 'validation.address.valid'
          }
        ],
        phone: [
          {
            constraint: this.$v.hcpForm.phone.$error && !this.$v.hcpForm.phone.validNumber,
            message: 'validation.phone.valid_number'
          }
        ],
        address: [
          {
            constraint: this.$v.hcpForm.address.$error && !this.$v.hcpForm.address.required,
            message: this.$t('settings.hcp.fax_or_address_required')
          }
        ],
        state: [
          {
            constraint: this.$v.hcpForm.state.$error && !this.$v.hcpForm.state.required,
            message: this.$t('settings.hcp.fax_or_address_required')
          }
        ],
        city: [
          {
            constraint: this.$v.hcpForm.city.$error && !this.$v.hcpForm.city.required,
            message: this.$t('settings.hcp.fax_or_address_required')
          }
        ],
        faxNumber: [
          {
            constraint: this.$v.hcpForm.fax_number.$error && !this.$v.hcpForm.fax_number.required,
            message: this.$t('settings.hcp.fax_or_address_required')
          },
          {
            constraint:
              this.$v.hcpForm.fax_number.$error && !this.$v.hcpForm.fax_number.validNumber,
            message: 'validation.fax.valid_number'
          }
        ],
        directEmailAddress: [
          {
            constraint:
              this.$v.hcpForm.direct_email_address.$error &&
              !this.$v.hcpForm.direct_email_address.email,
            message: 'validation.email.form_error_msg'
          }
        ]
      }
    }
  },

  created() {
    if (this.provider) {
      this.hcpForm = cloneDeep(this.provider)
      if (this.hcpForm.phone) this.hcpForm.phone = getPhone(this.hcpForm.phone)
      if (this.hcpForm.fax_number) this.hcpForm.fax_number = getPhone(this.hcpForm.fax_number)
      this.hcpForm.address = this.provider.address
      this.hcpForm.isSharingEnabled = this.isSharingEnabled
      this.isPrimary = this.hcpForm.is_primary

      // Added programmatically to avoid it being triggered on initial data setup
      this.$watch('hcpForm', () => this.$emit('update', this.$v.$anyDirty), { deep: true })
    }
  },

  methods: {
    phone() {
      return {
        maskFormat: getPhoneMaskFormat(this.countryCode)
      }
    },

    getHcpInformation(omittedFields = []) {
      const data = {
        ...this.hcpForm,
        phone: this.hcpForm?.phone ? uploadPhone(this.hcpForm?.phone, this.countryCode) : '',
        fax_number: this.hcpForm?.fax_number
          ? uploadPhone(this.hcpForm?.fax_number, this.countryCode)
          : ''
      }
      if (!this.provider?.is_primary && this.isPrimary) {
        data.is_primary = this.isPrimary
      }

      omittedFields.forEach((field) => delete data[field])
      delete data.isSharingEnabled

      return Object.values(data).every((val) => !val) ? null : data
    },

    restoreOriginal() {
      this.hcpForm = cloneDeep(this.provider)
      this.$v.$reset()
    },

    async validate() {
      this.$v.$touch()
      await common.waitForAsyncValidation(this.$v)

      if (this.$v.$anyError || this.$v.$invalid) {
        common.scrollToInvalid('.input-error')
      }

      return !this.$v.$invalid
    }
  }
}
</script>

<style lang="scss" scoped>
.hcp-form-edit {
  &__checkbox-col {
    margin-top: 20px;
    padding: 0 15px;
  }

  &__checkbox {
    &--disabled {
      pointer-events: none;
    }
  }
}
</style>
