import { i18n } from '@/plugins/i18n.js'
import {
  WORKFLOW_STATE_COMPLETE,
  WORKFLOW_STATE_RESULT_AMENDED,
  WORKFLOW_STATE_RESULT_APPROVED,
  WORKFLOW_STATE_RESULT_CORRECTED,
  WORKFLOW_STATE_RESULT_FOLLOW_UP_CANCELLED,
  WORKFLOW_STATE_RESULT_FOLLOW_UP_ENABLED,
  WORKFLOW_STATE_RESULT_FOLLOW_UP_SCHEDULED
} from '@/constants/workflowStates.js'

export const RESULT_APPROVED_STATES = [
  WORKFLOW_STATE_COMPLETE,
  WORKFLOW_STATE_RESULT_APPROVED,
  WORKFLOW_STATE_RESULT_AMENDED,
  WORKFLOW_STATE_RESULT_CORRECTED,
  // Wheel follow up call scheduled means the result is already been approved
  WORKFLOW_STATE_RESULT_FOLLOW_UP_SCHEDULED,
  WORKFLOW_STATE_RESULT_FOLLOW_UP_ENABLED,
  WORKFLOW_STATE_RESULT_FOLLOW_UP_CANCELLED
]

export const RESULTS_LOOKUP = Object.freeze({
  RESULT_POSITIVE: 'positive',
  RESULT_NEGATIVE: 'negative',
  RESULT_INCONCLUSIVE: 'indeterminate'
})

// http://hl7.org/fhir/r4/valueset-observation-interpretation.html
export const INTERPRETATIONS = {
  ABNORMAL: 'A',
  CRITICAL_ABNORMAL: 'AA',
  CRITICAL_LOW: 'LL',
  SIGNIFICANTLY_LOW: 'LU',
  LOW: 'L',
  NORMAL: 'N',
  HIGH: 'H',
  SIGNIFICANTLY_HIGH: 'HU',
  CRITICAL_HIGH: 'HH',
  NEGATIVE: 'NEG',
  NOT_DETECTED: 'ND',
  POSITIVE: 'POS',
  DETECTED: 'DET',
  NON_REACTIVE: 'NR',
  REACTIVE: 'RR',
  OUTSIDE_THRESHOLD: 'EX'
}

export const NORMAL_INTERPRETATION_CODES = [
  INTERPRETATIONS.NORMAL,
  INTERPRETATIONS.NEGATIVE,
  INTERPRETATIONS.NOT_DETECTED,
  INTERPRETATIONS.NON_REACTIVE
]

export const ABNORMAL_INTERPRETATION_CODES = [
  INTERPRETATIONS.ABNORMAL,
  INTERPRETATIONS.CRITICAL_ABNORMAL,
  INTERPRETATIONS.CRITICAL_LOW,
  INTERPRETATIONS.SIGNIFICANTLY_LOW,
  INTERPRETATIONS.SIGNIFICANTLY_HIGH,
  INTERPRETATIONS.LOW,
  INTERPRETATIONS.HIGH,
  INTERPRETATIONS.CRITICAL_HIGH,
  INTERPRETATIONS.DETECTED,
  INTERPRETATIONS.POSITIVE,
  INTERPRETATIONS.REACTIVE,
  INTERPRETATIONS.OUTSIDE_THRESHOLD
]

export const CRITICALLY_ABNORMAL_INTERPRETATION_CODES = [
  INTERPRETATIONS.CRITICAL_ABNORMAL,
  INTERPRETATIONS.CRITICAL_LOW,
  INTERPRETATIONS.CRITICAL_HIGH
]

export const isAbnormalInterpretationString = (interpretation) =>
  !/normal|negative|not detected|non-reactive/i.test(interpretation)

export const INTERPRETATION_RANGES = [
  {
    code: INTERPRETATIONS.CRITICAL_ABNORMAL,
    label: i18n.t('interpretation_ranges.critical_abnormal')
  },
  {
    code: INTERPRETATIONS.CRITICAL_LOW,
    label: i18n.t('interpretation_ranges.critical_low')
  },
  {
    code: INTERPRETATIONS.SIGNIFICANTLY_LOW,
    label: i18n.t('interpretation_ranges.significantly_low')
  },
  {
    code: INTERPRETATIONS.LOW,
    label: i18n.t('interpretation_ranges.low')
  },
  {
    code: INTERPRETATIONS.NORMAL,
    label: i18n.t('interpretation_ranges.normal')
  },
  {
    code: INTERPRETATIONS.HIGH,
    label: i18n.t('interpretation_ranges.high')
  },
  {
    code: INTERPRETATIONS.SIGNIFICANTLY_HIGH,
    label: i18n.t('interpretation_ranges.significantly_high')
  },
  {
    code: INTERPRETATIONS.CRITICAL_HIGH,
    label: i18n.t('interpretation_ranges.critical_high')
  }
]

export const INTERPRETATION_RANGE_LABELS = INTERPRETATION_RANGES.map((range) => range.label)

export const getInterpretationRangeByCode = (code) => {
  return INTERPRETATION_RANGES.find((range) => range.code === code)
}

export const ADMIN = 'admin'
export const PATIENT = 'patient'
const AUTOMATED = 'automated'
const IXCONNECT = 'ixconnect'
const PROVIDER = 'provider'

export const filtersForResultActivitiesRequest = {
  recipient: PROVIDER,
  event_source__in: `${PATIENT}, ${AUTOMATED}, ${IXCONNECT}`
}

export const PROVIDER_APPOINTMENT_CANCELLED = 'provider_appointment_canceled'
export const PROVIDER_APPOINTMENT_SCHEDULED = 'provider_appointment_scheduled'
export const RESULTS_RELEASED_EFAX = 'results_released_efax'
export const RESULTS_RELEASED_EMAIL = 'results_released_email'
export const RESULTS_RELEASED_MAIL = 'results_released_mail'

export const resultActivityEventTypes = {
  [PROVIDER_APPOINTMENT_CANCELLED]: 'Doc’s appointment',
  [PROVIDER_APPOINTMENT_SCHEDULED]: 'Doc’s appointment',
  [RESULTS_RELEASED_EFAX]: 'eFax results',
  [RESULTS_RELEASED_EMAIL]: 'Email results',
  [RESULTS_RELEASED_MAIL]: 'Mail results'
}
