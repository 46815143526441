<i18n src="./locales/lang.json"></i18n>

<template>
  <i18n tag="base-text" as="p" class="telehealth-session" path="select_test.telehealth.text">
    <template #link>
      <RouterLink :to="to" class="v4-anchor">
        {{ $t('select_test.telehealth.book_session') }}
      </RouterLink>
    </template>
  </i18n>
</template>

<script>
export default {
  name: 'TelehealthSession',

  props: {
    to: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.telehealth-session {
  @include media-breakpoint-up(md) {
    text-align: center;
  }
}
</style>
