export const isExternalLink = (link) => {
  try {
    return new URL(link).origin !== window.location.origin
  } catch {
    return false
  }
}

export const isAbsoluteUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

export const addTargetBlankToLinks = (value = '') => {
  if (typeof value !== 'string') return value

  return value.replace(
    /<a\s+(?![^>]*target=)([^>]*?href=["']([^"']+)["'][^>]*)>/gi,
    (match, attributes, href) => {
      if (isExternalLink(href)) {
        return `<a ${attributes} target="_blank" rel="noopener noreferrer">`
      }
      return match
    }
  )
}

export const scheduleIterations = (callback, delay, iterations) => {
  let timeoutId = null

  const promise = new Promise((resolve) => {
    let count = 0

    const runIteration = () => {
      // First delay before starting iterations
      timeoutId = setTimeout(() => {
        callback(count)
        count++

        if (count >= iterations) {
          resolve()
          return
        }

        runIteration() // Schedule next iteration
      }, delay)
    }

    runIteration() // Start first iteration
  })

  return {
    promise,
    cleanup: () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
    }
  }
}
