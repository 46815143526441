<template>
  <header class="questionnaire-section-header">
    <BaseHeading v-if="section.name" tag="h2" :as="as" :variant="variant">
      {{ section.name }}
    </BaseHeading>

    <BaseText
      v-if="section.description"
      as="p"
      variant="large-regular"
      class="questionnaire-section-header__description"
      v-html="section.description"
    />
  </header>
</template>

<script>
export default {
  name: 'QuestionnaireSectionHeader',

  props: {
    section: {
      type: Object,
      required: true
    },

    as: {
      type: String,
      default: 'h2'
    },

    variant: {
      type: String,
      default: 'semibold'
    }
  }
}
</script>

<style lang="scss" scoped>
.questionnaire-section-header {
  &__description {
    color: $v3-text-color-dark-pale;
  }
}
</style>
