<template>
  <InputSelectV3
    :value="state"
    :options-data="states"
    :label="$t('form.address.float_label.state')"
    :default-option="$t('form.address.default_option.state')"
    value-by="abbreviation"
    @input="$emit('input', $event)"
  />
</template>

<script>
import InputSelectV3 from '@/components/ui-components/InputSelect/InputSelectV3.vue'

export default {
  name: 'QuestionAddressState',

  components: {
    InputSelectV3
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    states: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      state: null
    }
  }
}
</script>
