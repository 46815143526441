import { CONFIG } from '@/constants/config.js'
import { CONTENT_TYPES, DEFAULT_ORG_CONTENT_ID } from '@/constants/content.js'
import { WORKFLOW_STATES } from '@/constants/workflowStates.js'
import { errorHandler } from '@/utils/errorUtils.js'
import {
  fetchWorkflowContent,
  transformWelcomePageContent,
  filterValidWorkflowItems,
  reduceWorkflowContent,
  fetchNorthstarContent
} from '@/utils/storeUtils.js'

const state = {
  welcomePage: {
    hero: {
      heading: '',
      description: '',
      heroImage: {},
      ctaPrimary: {},
      ctaSecondary: {}
    },
    whatsAhead: {
      steps: Array.from({ length: 4 }).map(() => ({
        title: 'Step name loading...',
        description: 'Step description loading...'
      })),
      disclaimer: 'Disclaimer loading...'
    }
  },
  workflow: [],
  isWorkflowContentLoading: false,
  testDetails: [],
  telehealthInfoPage: {}
}

export const FETCH_WELCOME_PAGE_CONTENT = 'FETCH_WELCOME_PAGE_CONTENT'
export const FETCH_WORKFLOW_CONTENT = 'FETCH_WORKFLOW_CONTENT'
export const FETCH_TEST_DETAILS_CONTENT = 'FETCH_TEST_DETAILS_CONTENT'
export const FETCH_TELEHEALTH_INFO_PAGE_CONTENT = 'FETCH_TELEHEALTH_INFO_PAGE_CONTENT'
const SET_IS_WORKFLOW_LOADING = 'SET_IS_WORKFLOW_LOADING'
const SET_WELCOME_PAGE_CONTENT = 'SET_WELCOME_PAGE_CONTENT'
const SET_WORKFLOW_CONTENT = 'SET_WORKFLOW_CONTENT'
const SET_TEST_DETAILS_CONTENT = 'SET_TEST_DETAILS_CONTENT'
const SET_TELEHEALTH_INFO_PAGE_CONTENT = 'SET_TELEHEALTH_INFO_PAGE_CONTENT'

const getters = {
  cmsWelcomePageContent: (state) => state.welcomePage,
  cmsWorkflowContent: (state) => state.workflow,
  cmsIsWorkflowContentLoading: (state) => state.isWorkflowContentLoading,
  cmsTestDetailsContent: (state) => state.testDetails,
  cmsTelehealthInfoPageContent: (state) => state.telehealthInfoPage
}

const mutations = {
  [SET_WELCOME_PAGE_CONTENT]: (state, { content }) => {
    if (!content || content.length === 0) {
      state.welcomePage = {}
      return
    }

    const welcomeData = content[0]?.meta?.body

    if (!welcomeData) {
      state.welcomePage = {}
      return
    }

    state.welcomePage = transformWelcomePageContent(welcomeData)
  },

  [SET_IS_WORKFLOW_LOADING]: (state, value) => {
    state.isWorkflowContentLoading = value
  },

  [SET_WORKFLOW_CONTENT]: (state, { defaultContent, orgSpecificContent }) => {
    const validWorkflowStates = Array.from(WORKFLOW_STATES.keys())

    const mergedContent = [...defaultContent, ...orgSpecificContent]

    const validItems = filterValidWorkflowItems(mergedContent, validWorkflowStates)

    state.workflow = reduceWorkflowContent(validItems, validWorkflowStates)
  },

  [SET_TEST_DETAILS_CONTENT]: (state, { content }) => {
    if (!content || content.length === 0) {
      state.testDetails = []
      return
    }
    // Filter out unnecessary content types
    const includeProps = ['title', 'body', 'preface', 'slug', 'tags', 'contenttype']
    const filteredContent = content.map((item) => {
      const filtered = {}
      includeProps.forEach((prop) => {
        if (item?.meta?.[prop] !== undefined) {
          filtered[prop] = item.meta[prop]
        }
      })

      return filtered
    })

    state.testDetails = filteredContent
  },

  [SET_TELEHEALTH_INFO_PAGE_CONTENT]: (state, { content, modalContent }) => {
    if (!content || content.length === 0 || !content[0]?.meta?.body) {
      return
    }
    // Take the important data from the page content
    state.telehealthInfoPage = {
      body: content[0]?.meta?.body,
      preface: content[0]?.meta?.preface,
      buttons: content[0]?.meta?.take_action,
      modalContent: modalContent[0]?.meta?.body
    }
  }
}

const actions = {
  [FETCH_WELCOME_PAGE_CONTENT]: async ({ commit, rootGetters }) => {
    try {
      const content = await fetchWorkflowContent(
        rootGetters,
        rootGetters.getConfig(CONFIG.ORG_CONTENT_ID),
        {
          contenttype: CONTENT_TYPES.WELCOME_PAGE
        }
      )

      commit(SET_WELCOME_PAGE_CONTENT, {
        content: content.data.items
      })
    } catch (error) {
      errorHandler({
        sentryErrMessage: `FAILED TO FETCH CMS WELCOME PAGE CONTENT: ${
          error.response?.data?.message || error
        }`
      })
    }
  },

  [FETCH_TELEHEALTH_INFO_PAGE_CONTENT]: async ({ commit }, { orgContentId } = {}) => {
    try {
      const promises = [
        fetchNorthstarContent(DEFAULT_ORG_CONTENT_ID, {
          tags: `urn:cms:telehealth-appointment-info`
        }),
        fetchNorthstarContent(DEFAULT_ORG_CONTENT_ID, {
          tags: `urn:cms:product:telehealth`
        })
      ]

      if (orgContentId && orgContentId !== DEFAULT_ORG_CONTENT_ID) {
        promises.push(
          fetchNorthstarContent(orgContentId, {
            tags: `urn:cms:telehealth-appointment-info`
          })
        )
        promises.push(
          fetchNorthstarContent(orgContentId, {
            tags: `urn:cms:product:telehealth`
          })
        )
      }

      const [pageContent, modalContent, orgSpecificPageContent, orgSpecificModalContent] =
        await Promise.all(promises)

      // If org specific content is available, use it, otherwise use default content
      commit(SET_TELEHEALTH_INFO_PAGE_CONTENT, {
        content: orgSpecificPageContent?.data?.items || pageContent?.data?.items,
        modalContent: orgSpecificModalContent?.data?.items || modalContent?.data?.items
      })
    } catch (error) {
      errorHandler({
        sentryErrMessage: `FAILED TO FETCH CMS TELEHEALTH INFO PAGE CONTENT: ${
          error.response?.data?.message || error
        }`
      })
    }
  },

  [FETCH_WORKFLOW_CONTENT]: async ({ commit, rootGetters }, { orgContentId, params } = {}) => {
    try {
      commit(SET_IS_WORKFLOW_LOADING, true)

      const defaultContentPromise = fetchWorkflowContent(
        rootGetters,
        DEFAULT_ORG_CONTENT_ID,
        params
      )
      const orgSpecificContentPromise = fetchWorkflowContent(rootGetters, orgContentId, params)

      const [defaultContent, orgSpecificContent] = await Promise.all([
        defaultContentPromise,
        orgSpecificContentPromise
      ])

      commit(SET_WORKFLOW_CONTENT, {
        defaultContent: defaultContent.data.items,
        orgSpecificContent: orgSpecificContent.data.items
      })
    } catch (error) {
      errorHandler({
        sentryErrMessage: `FAILED TO FETCH CMS WORKFLOW CONTENT: ${
          error.response?.data?.message || error
        }`
      })
    } finally {
      commit(SET_IS_WORKFLOW_LOADING, false)
    }
  },

  [FETCH_TEST_DETAILS_CONTENT]: async ({ commit }, { orgContentId, params } = {}) => {
    try {
      const defaultContentPromise = fetchNorthstarContent(DEFAULT_ORG_CONTENT_ID, params)

      let promises = [defaultContentPromise]
      if (orgContentId && orgContentId !== DEFAULT_ORG_CONTENT_ID) {
        const orgSpecificContentPromise = fetchNorthstarContent(orgContentId, params)
        promises.push(orgSpecificContentPromise)
      }

      const [defaultContent, orgSpecificContent] = await Promise.all(promises)

      // Extract product tags from org specific content
      const orgSpecificProductTags =
        orgSpecificContent?.data?.items?.reduce((tags, item) => {
          const productTags =
            item.meta?.tags?.filter((tag) => tag.startsWith('urn:cms:product:')) || []
          return [...tags, ...productTags]
        }, []) || []

      // Filter default content to only include items with product tags not in org specific content
      const filteredDefaultItems = defaultContent.data.items.filter((item) => {
        const itemProductTags =
          item.meta?.tags?.filter((tag) => tag.startsWith('urn:cms:product:')) || []
        return !itemProductTags.some((tag) => orgSpecificProductTags.includes(tag))
      })

      // Merge filtered default items with org specific items
      const data = {
        items: [...(orgSpecificContent?.data?.items || []), ...filteredDefaultItems]
      }

      commit(SET_TEST_DETAILS_CONTENT, {
        content: data.items
      })
    } catch (error) {
      errorHandler({
        toastedMessage: 'Error fetching test details content',
        sentryErrMessage: 'Failed to fetch test details content',
        error
      })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
