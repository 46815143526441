<template>
  <BNavbar
    :toggleable="toggleBreakpoint"
    :class="[isFooter ? 'container navbar--footer' : 'container']"
    type="primary"
  >
    <vue-skip-to to="#main" :label="$t('skip_link')" />
    <div v-if="!isFooter" class="navbar__brand-and-burger">
      <BNavbarBrand
        :to="logoRouterLinkTo ? $i18nRoute({ name: logoRouterLinkTo }) : null"
        :href="footerLogoHref || '#'"
        :class="{ 'navbar__main--disabled': isDisabled }"
        class="navbar__logo"
      >
        <img :src="logo" alt="" />
        <span class="sr-only">{{ orgName }}</span>
      </BNavbarBrand>

      <b-navbar-toggle
        :class="{ isActive: showMobileMenu }"
        class="navbar__hamburger-menu"
        :target="`navbar-collapse-${id}`"
      >
        <span class="hamburger-menu-item" />
        <span class="hamburger-menu-item" />
        <span class="hamburger-menu-item" />
      </b-navbar-toggle>
    </div>
    <BCollapse :id="`navbar-collapse-${id}`" ref="collapse" is-nav>
      <div :class="[isFooter ? 'navbar__main-wrap' : 'navbar__main-wrap row']">
        <BNavbarNav :class="[{ 'navbar__main--hidden': isHidden }, 'navbar__main']" tag="span">
          <div
            class="navbar__links-container"
            :class="{ 'navbar__links-container--footer': isFooter }"
          >
            <div
              v-for="(item, index) in linkGroups"
              :key="`link-group-${index}`"
              :class="[
                'navbar__links-wrap',
                isFooter ? `col-6 col-md-6 col-lg-${desktopColumnWidth} my-3 mt-md-0` : '',
                `${item.addLinkItemClass ? item.addLinkItemClass : ''}`
              ]"
            >
              <BNavItemDropdown v-if="item.dropdownItems" no-caret>
                <template #button-content>
                  <div class="navbar__dropdown-wrap">
                    <span class="navbar__dropdown">{{ $t(item.text) }}</span>
                    <IconBase
                      :params="{
                        width: '10',
                        height: '7',
                        viewBox: '0 0 10 7'
                      }"
                      class="navbar__arrow-down-icon"
                    >
                      <IxIconArrowDown />
                    </IconBase>
                  </div>
                </template>

                <div
                  v-for="(dropdownItem, dropdownIndex) in item.dropdownItems"
                  :key="`dropdown-${dropdownIndex}`"
                >
                  <BDropdownItem
                    :href="dropdownItem.href ? dropdownItem.href : '#'"
                    :target="dropdownItem.href ? '_blank' : null"
                    @click="dropdownItem.onClick"
                  >
                    <template v-if="dropdownItem.hasIcon">
                      <BImg
                        :src="`/src/assets/img/common-images/navbar-icon_${dropdownIndex}.svg`"
                        class="navbar__dropdown-item-icon"
                        alt=""
                      />
                      <span :style="{ color: dropdownItem.color }">
                        {{ $t(dropdownItem.text) }}
                      </span>
                    </template>
                    <template v-else>
                      {{ $t(dropdownItem.text) }}
                    </template>
                  </BDropdownItem>
                </div>
              </BNavItemDropdown>

              <div v-else class="navbar__links">
                <h2 v-if="item.title" class="navbar__links-title" v-html="$t(item.title)" />
                <ul class="navbar__list-group">
                  <template v-for="(link, linkIndex) in item.links">
                    <BNavItem
                      v-if="link.to || link.path"
                      :key="`link-${linkIndex}`"
                      :disabled="isDisabled"
                      :to="
                        $i18nRoute(
                          link.to
                            ? {
                                name: link.to,
                                hash: link.elmId ? '#' + link.elmId : ''
                              }
                            : { path: link.path }
                        )
                      "
                      class="navbar__links-link"
                    >
                      {{ $t(link.text) }}
                    </BNavItem>
                    <BNavItem
                      v-else-if="link.href"
                      :key="`link-${linkIndex}`"
                      :disabled="isDisabled"
                      :href="link.href"
                      target="_blank"
                      class="navbar__links-link"
                    >
                      {{ $t(link.text) }}
                    </BNavItem>
                    <BNavItem
                      v-else-if="link.mailTo"
                      :key="`link-${linkIndex}`"
                      :href="`mailto:${link.mailTo}`"
                      target="_blank"
                      class="navbar__links-link"
                    >
                      {{ $t(link.text) }}
                    </BNavItem>
                    <BNavItem
                      v-else-if="link.phone"
                      :key="`link-${linkIndex}`"
                      :href="`tel:${link.phone}`"
                      class="navbar__links-link"
                    >
                      {{ $t(link.text) }}
                    </BNavItem>
                    <b-nav-text v-else-if="link.text" :key="`link-${linkIndex}`">
                      {{ $t(link.text) }}
                    </b-nav-text>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </BNavbarNav>

        <BNavbarNav v-if="isLoginSection" tag="span" class="navbar__main navbar__main--align-right">
          <div class="navbar__links-container">
            <ul
              v-for="(item, index) in loginSectionLinks"
              :key="`login-section-links-${index}`"
              :class="`navbar__links-wrap ${item.addLinkItemClass ? item.addLinkItemClass : ''}`"
              class="navbar__list-group"
            >
              <template v-if="item.dropdownItems">
                <BNavItemDropdown
                  :class="[isAuthenticated ? 'd-none d-lg-block' : '']"
                  role="menu"
                  no-caret
                  right
                >
                  <template #button-content>
                    <div v-if="item.icon" class="navbar__dropdown-wrap">
                      <span class="sr-only">{{ $t('alt_text.open_profile_menu') }}</span>
                      <IconBase
                        v-if="item.icon === 'profile-v3'"
                        :params="{
                          width: '19',
                          height: '20',
                          viewBox: '0 0 19 20'
                        }"
                        :class="userName ? 'mr-2' : ''"
                        :alt="$t('alt_text.open_profile_menu')"
                      >
                        <IxIconProfile />
                      </IconBase>
                      <img v-else :src="item.icon" :class="[userName ? 'pr-2' : '']" alt="" />

                      <span v-if="userName" v-html="userName" />

                      <IconBase
                        :params="{
                          width: '10',
                          height: '7',
                          viewBox: '0 0 10 7'
                        }"
                        class="navbar__arrow-down-icon"
                        :alt="$t('alt_text.open_profile_menu')"
                      >
                        <IxIconArrowDown />
                      </IconBase>
                    </div>
                    <span v-else class="navbar__dropdown">{{ $t(item.text) }}</span>
                  </template>

                  <BDropdownItem
                    v-for="(dropdownItem, dropdownIndex) in item.dropdownItems"
                    :key="`login-section-dropdown-${dropdownIndex}`"
                    :to="$i18nRoute({ name: dropdownItem.to })"
                  >
                    <div class="navbar__dropdown-item" @click="handleDropdownClick(dropdownItem)">
                      {{ $t(dropdownItem.text) }}
                    </div>
                  </BDropdownItem>
                </BNavItemDropdown>

                <ul v-if="isAuthenticated" class="d-lg-none navbar__list-group">
                  <template v-for="(link, linkIndex) in item.dropdownItems">
                    <BNavItemDropdown
                      v-if="link.dropdownItems"
                      :key="`login-section-link-${linkIndex}`"
                      :text="$t(link.text)"
                      class="navbar__links-wrap"
                    >
                      <BDropdownItem
                        v-for="(dropdownItem, dropdownItemIndex) in link.dropdownItems"
                        :key="`login-section-link-${linkIndex}-${dropdownItemIndex}`"
                        :to="$i18nRoute({ name: dropdownItem.to })"
                      >
                        {{ $t(dropdownItem.text) }}
                      </BDropdownItem>
                    </BNavItemDropdown>

                    <BNavItem
                      v-else
                      :key="`login-section-link-${linkIndex}`"
                      :to="$i18nRoute({ name: link.to })"
                      class="navbar__links-wrap"
                    >
                      {{ $t(link.text) }}
                    </BNavItem>
                  </template>
                </ul>
              </template>

              <BNavItem
                v-else-if="item.to"
                :to="$i18nRoute({ name: item.to, params: item.linkParams })"
              >
                <template v-if="item.isBtn">
                  <BaseBtn tag="span" class="d-none d-lg-inline-flex" v-bind="item.btnProps">
                    <IconBase v-if="item.icon" :params="item.icon.params">
                      <component :is="item.icon.component" />
                    </IconBase>
                    <span>{{ $t(item.text) }}</span>
                  </BaseBtn>
                  <span class="d-lg-none">{{ $t(item.text) }}</span>
                </template>
                <span v-else :class="[!isMobile ? item.btnClass : '']">{{ $t(item.text) }}</span>
              </BNavItem>

              <BNavItem v-else link-classes="nav-link-button">
                <BaseBtn secondary class="d-none d-lg-inline-flex" @click="item.onClick">
                  <IconBase v-if="item.icon" :params="item.icon.params" style="margin-top: -2px">
                    <component :is="item.icon.component" />
                  </IconBase>
                  <span>{{ $t(item.text) }}</span>
                </BaseBtn>
                <BaseBtn class="d-lg-none navbar__ghost-btn" @click="item.onClick">
                  <span>{{ $t(item.text) }}</span>
                </BaseBtn>
              </BNavItem>
            </ul>
          </div>
        </BNavbarNav>
      </div>
    </BCollapse>
  </BNavbar>
</template>

<script>
import { mapGetters } from 'vuex'
import { CONFIG } from '@/constants/config.js'

import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconArrowDown from '@/components/icons/IxIconArrowDown.vue'
import IxIconProfile from '@/components/icons/IxIconProfile.vue'
import common from '@/classes/common.js'
import IxIconHome from '@/components/icons/IxIconHome.vue'

export default {
  name: 'NavbarV3',
  components: {
    IxIconHome,
    IconBase,
    IxIconArrowDown,
    IxIconProfile
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isFooter: {
      type: Boolean,
      default: false
    },
    isHidden: {
      type: Boolean,
      default: false
    },
    isLoginSection: {
      type: Boolean,
      default: true
    },
    linkGroups: {
      type: Array,
      default: () => []
    },
    loginSectionLinks: {
      type: Array,
      default: () => []
    },
    toggleBreakpoint: {
      type: [Boolean, String],
      default: 'lg'
    },
    userName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showMobileMenu: false
    }
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'defaultSignedInRouterLinkTo',
      'getThemeValue',
      'organizationName',
      'getConfig'
    ]),
    logo() {
      return common.cleanCssThemeUrl(this.getThemeValue('--logo-desktop'))
    },
    orgName() {
      return this.organizationName || this.getConfig(CONFIG.COMPANY_NAME)
    },
    isMobile() {
      return ['xs', 'sm', 'md'].includes(this.$mq)
    },
    logoRouterLinkTo() {
      if (this.footerLogoHref || this.isDisabled) {
        return ''
      } else if (this.isAuthenticated) {
        return this.defaultSignedInRouterLinkTo
      } else {
        const navbarConf = this.getConfig(CONFIG.NAVBAR)
        return navbarConf?.logo?.signedOutRouterLinkTo ?? null
      }
    },
    footerLogoHref() {
      const navbarConf = this.getConfig(CONFIG.NAVBAR)
      return this.isFooter && navbarConf?.logo?.footer?.href
    },
    desktopColumnWidth() {
      if (this.linkGroups.length === 0) {
        return 3
      }

      return Math.max(3, Math.min(12 / this.linkGroups.length, 4))
    }
  },
  mounted() {
    this.$refs.collapse.$on('show', () => {
      this.showMobileMenu = true
      this.$emit('mobile-menu:visible', true)
    })
    this.$refs.collapse.$on('hidden', () => {
      this.showMobileMenu = false
      this.$emit('mobile-menu:visible', false)
    })
  },
  methods: {
    handleDropdownClick(item) {
      if (item.onClick) {
        item.onClick(item.text)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$hamburger-menu-item-width: 14px;
$hamburger-menu-item-height: 3px;
$v3-hamburger-menu-item-bg-color: $v3-color-blue-dark;
$hamburger-menu-item-margin: 2px auto;
$hamburger-menu-item-margin-is-active: 5px auto;
$hamburger-menu-item-transition: all 0.3s ease-in-out;
$main-disabled-nav-link-color: $font-color-grey;
$v3-nav-link-color: $v3-color-dark-shark-gray;
$nav-link-active-focus-hover-color: $font-color-primary;
$v3-nav-link-active-focus-hover-color: $v3-secondary;
$dropdown-icon-ml: 10px;
$dropdown-icon-width: 12px;
$dropdown-item-color: $font-color-black;
$dropdown-menu-item-mbp-down-md-pt: 8px;
$dropdown-menu-item-mbp-down-md-pb: 8px;
$dropdown-menu-item-mbp-down-md-mb: 6px;
$dropdown-item-icon-height: 22px;
$dropdown-item-icon-mr: 8px;
$footer-padding: 0 35px;
$footer-links-wrap-margin: 0 15px;
$footer-links-title-color: $v3-color-regent-gray;

/*
* For footer, it can be:
*  --footer-menu-item-disabled-color-xs
*  --footer-menu-item-hover-color-xs
*  (type: 'footer-menu-item', state: 'hover', style: 'color', breakpoint-key: 'xs')
* For top-navigation, it can be:
*  --header-menu-item-default-color-xs OR
*  --header-menu-item-active-color-md
*  (type: 'header-menu-item', state: 'active', style: 'color', breakpoint-key: 'md')
*/
@function menu-item-var-name($type, $state, $style, $breakpoint-key) {
  @return theme-var-name($type, $state, $style, '', $breakpoint-key);
}

// If a state specific CSS variable does not exist, it should fallback to the value of the given variant
// Fallback priority example of font-size in "hover" site in outlines:
//    Check on Hover font-size in outline, if it does not exist let's check on default outline font-size,
//    finally fallback to the default font-size:
//    --header-menu-item-active-color-md -> --header-menu-item-default-color-md
@function fallback-to-no-state-var($type, $state, $style, $defaultValue, $breakpoint-key) {
  @return responsive-fallback-to-no-variant-var(
    $type,
    $state,
    $style,
    $defaultValue,
    '',
    $breakpoint-key
  );
}

@mixin brand-menu-item(
  $breakpoint-key,
  // Can be "header-menu-item" OR "footer-menu-item"
  $type,
  // "default", "active", "hover", "disabled"
  $state,
  $default-font-size,
  $default-text-color: $v3-nav-link-color,
  $default-font-weight: normal,
  $default-text-decoration: none,
  $default-text-underline-offset: auto
) {
  color: var(
    menu-item-var-name($type, $state, 'text-color', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'text-color', $default-text-color, $breakpoint-key)
  );

  // E.g. --header-menu-item-active-color or --footer-menu-item-disabled-color

  font-size: var(
    menu-item-var-name($type, $state, 'font-size', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'font-size', $default-font-size, $breakpoint-key)
  );
  font-weight: var(
    menu-item-var-name($type, $state, 'font-weight', $breakpoint-key),
    fallback-to-no-state-var($type, $state, 'font-weight', $default-font-weight, $breakpoint-key)
  );

  /* Sadly the !important needs at the end, because body > a.hover has
  "text-decoration: none !important;" in main.js, and I'm not comfortable to remove it.
  Who knows where will be underlines appearing in pp v2 or v3 if I remove this? */
  text-decoration: var(
    menu-item-var-name($type, $state, 'text-decoration', $breakpoint-key),
    fallback-to-no-state-var(
      $type,
      $state,
      'text-decoration',
      $default-text-decoration,
      $breakpoint-key
    )
  ) !important;
  text-underline-offset: var(
    menu-item-var-name($type, $state, 'text-underline-offset', $breakpoint-key),
    fallback-to-no-state-var(
      $type,
      $state,
      'text-underline-offset',
      $default-text-underline-offset,
      $breakpoint-key
    )
  );
}

/*
  Unfortunately mixin cannot take another mixin as an argument. But functions can, so replacing node-sass to dartsass
  could help to make this function reusable by passing a callbackFn $function into it.
  https://sass-lang.com/documentation/modules/meta#functions
*/
@mixin responsive-brand-menu-item($args...) {
  @each $breakpoint-key, $_ in $grid-breakpoints {
    @if $breakpoint-key != 'xs' {
      // Apply media query from sm and above (starting with @media (min-width: 576px))
      @include media-breakpoint-up(#{$breakpoint-key}) {
        @include brand-menu-item($breakpoint-key, $args...);
      }
    } @else {
      // Default, mobile first design @media (min-width: 0px)
      @include brand-menu-item('', $args...);
    }
  }
}

.navbar {
  margin: auto;
  padding: 0;
  width: 100%;

  @include media-breakpoint-up(sm) {
    body:not(.pharma-patient-portal) & {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .navbar-nav {
    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &__brand-and-burger {
    display: block;

    @include media-breakpoint-down(md) {
      align-items: center;
      display: flex;
      height: 60px;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__logo {
    align-items: center;
    display: flex;
    margin-right: 32px;
    padding: 0;

    img {
      max-width: 100%;
    }
  }

  &__hamburger-menu {
    padding-right: 0;

    &:focus {
      outline: none;
    }

    .hamburger-menu-item {
      background-color: $v3-hamburger-menu-item-bg-color;
      display: block;
      height: $hamburger-menu-item-height;
      margin: $hamburger-menu-item-margin;
      transition: $hamburger-menu-item-transition;
      width: $hamburger-menu-item-width;
    }

    &:hover {
      cursor: pointer;
    }

    &.isActive {
      .hamburger-menu-item {
        margin: $hamburger-menu-item-margin-is-active;
      }

      .hamburger-menu-item:nth-child(2) {
        opacity: 0;
      }

      .hamburger-menu-item:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }

      .hamburger-menu-item:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }

  &__main-wrap {
    display: inline-block;
    margin: auto;
    width: 100%;

    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &--align-right {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__main {
    display: flex;
    justify-content: flex-start;

    &--align-right {
      justify-content: flex-end;
    }

    &--hidden {
      :deep(.navbar__links-wrap) {
        .nav-link {
          // stylelint-disable-line max-nesting-depth
          display: none;
        }
      }
    }

    &--disabled {
      pointer-events: none;

      .navbar__links-wrap {
        .nav-link:not(.nav-link-button) {
          // stylelint-disable-line max-nesting-depth
          @include responsive-brand-menu-item(
            'header-menu-item',
            'disabled',
            16px,
            $main-disabled-nav-link-color
          );
        }
      }
    }

    &:last-child {
      @include media-breakpoint-up(lg) {
        padding-right: 0;
      }
    }
  }

  &__links-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: flex-end;
    }

    &--footer {
      @include media-breakpoint-down(md) {
        flex-flow: row wrap;
      }
    }
  }

  &__list-group {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;

    // Default buttons don't have a border and by placing a secondary button next to it will
    // cause their height to be different,so we need to limit it here.
    // Fixing it in general is a bigger task and requires theming changes too.
    :deep(.base-btn) {
      max-height: 40px;
    }
  }

  &__links-wrap {
    padding-top: 0;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(lg) {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0;
      min-height: $v3-header-height;
      width: auto;
    }

    &--align-right {
      :deep(.nav-item) {
        .nav-link {
          // stylelint-disable-line max-nesting-depth
          @include media-breakpoint-up(lg) {
            // stylelint-disable-line max-nesting-depth
            padding-right: 0;
          }
        }
      }
    }

    .navbar__dropdown,
    :deep(.nav-link:not(.nav-link-button)),
    .dropdown-item {
      @include responsive-brand-menu-item('header-menu-item', 'default', 16px, $v3-nav-link-color);

      align-items: center;
      display: flex;

      &:hover {
        @include responsive-brand-menu-item(
          'header-menu-item',
          'hover',
          16px,
          $v3-nav-link-active-focus-hover-color
        );
      }

      &.router-link-active,
      &:active,
      &:focus {
        @include responsive-brand-menu-item(
          'header-menu-item',
          'active',
          16px,
          $v3-nav-link-active-focus-hover-color,
          600
        );
      }
    }

    &--colored .nav-link:not(.nav-link-button) {
      @include media-breakpoint-up(lg) {
        color: $v3-primary;
      }
    }
  }

  &__links {
    margin: 0 10px;

    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }

  &__links-title {
    @include responsive-brand-menu-item(
      'footer-menu-title',
      'default',
      16px,
      $footer-links-title-color,
      600
    );

    margin-bottom: 12px;
  }

  &__ghost-btn {
    @include responsive-brand-menu-item('header-menu-item', 'default', 16px, $v3-nav-link-color);

    border: 0;
    min-height: 0;
    padding: 0;

    &,
    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }

    &:focus {
      @include responsive-brand-menu-item(
        'header-menu-item',
        'active',
        16px,
        $v3-nav-link-active-focus-hover-color,
        600
      );
    }
  }

  &__dropdown-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__arrow-down-icon {
    margin-left: $dropdown-icon-ml;
    width: $dropdown-icon-width;
  }

  :deep(.dropdown-menu) {
    border: 0;
    border-radius: $v3-main-border-radius;
    box-shadow: 0 8px 24px rgb(120 120 120 / 0.1);

    @include media-breakpoint-down(md) {
      border: 0;
      box-shadow: none;
      padding: 0;
      text-align: start;
    }

    .dropdown-item {
      @include responsive-brand-menu-item('header-menu-item', 'default', 16px, $v3-nav-link-color);

      @include media-breakpoint-down(md) {
        margin-bottom: $dropdown-menu-item-mbp-down-md-mb;
        padding-bottom: $dropdown-menu-item-mbp-down-md-pb;
        padding-top: $dropdown-menu-item-mbp-down-md-pt;
      }

      &:hover .navbar__dropdown-item {
        @include responsive-brand-menu-item(
          'header-menu-item',
          'hover',
          13px,
          $v3-nav-link-active-focus-hover-color
        );
      }

      &.router-link-active {
        @include responsive-brand-menu-item(
          'header-menu-item',
          'active',
          16px,
          $v3-nav-link-active-focus-hover-color,
          600
        );

        .navbar__dropdown-item {
          // stylelint-disable-line max-nesting-depth
          font-weight: 500;
        }
      }

      &.active,
      &.dropdown-item:active {
        @include responsive-brand-menu-item(
          'header-menu-item',
          'active',
          16px,
          $v3-nav-link-active-focus-hover-color,
          600
        );

        background-color: unset;
      }
    }
  }

  &__dropdown-item-icon {
    height: $dropdown-item-icon-height;
    margin-right: $dropdown-item-icon-mr;
  }

  &--footer {
    @include media-breakpoint-down(md) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      .navbar__main {
        display: flex;
        flex-direction: column;
      }
    }

    .navbar__main-wrap,
    .navbar__main,
    .navbar__links-container {
      width: 100%;
    }

    .navbar-collapse {
      width: 100%;
    }

    .navbar__links-link {
      margin-bottom: 8px;
    }

    .navbar-nav :is(.nav-link:not(.nav-link-button), .navbar-text) {
      @include responsive-brand-menu-item('footer-menu-item', 'default', 14px, $v3-text-color-dark);

      padding: 0;

      &.navbar-text,
      &.navbar-text:hover,
      &.navbar-text:focus,
      &.navbar-text:active {
        color: $v3-text-color-dark;

        // See brand-menu-item mixin on why important is required here
        text-decoration: none !important;
      }

      &:hover,
      &.router-link-active {
        @include responsive-brand-menu-item(
          'footer-menu-item',
          'hover',
          14px,
          $v3-nav-link-active-focus-hover-color
        );
      }

      &:active,
      &:focus {
        @include responsive-brand-menu-item(
          'footer-menu-item',
          'active',
          14px,
          $v3-text-color-dark-pale
        );
      }

      &.router-link-active {
        font-weight: 600;
      }
    }

    .navbar__links-wrap {
      justify-content: flex-start;
      min-height: 0;

      &.navbar__links-wrap--align-right {
        @include media-breakpoint-up(lg) {
          // stylelint-disable-line max-nesting-depth
          margin-right: 0;
        }
      }
    }
  }
}
</style>
