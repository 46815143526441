<i18n src="./locales/lang.json"></i18n>

<template>
  <div class="row">
    <div class="col-12 col-md-8 col-lg-6 mx-auto">
      <QuestionnaireFormV3
        v-if="questionnaireData"
        ref="questionnaire"
        :questionnaire-data="questionnaireData"
        :answers="answers"
        :question-id="questionId"
        :has-sms="false"
        :has-terms="false"
        :has-submit-btn="true"
        :is-back-button-hidden="true"
        :is-step-based="true"
        @navigate="handleNavigation"
        @submit="submit"
      />
    </div>
  </div>
</template>

<script>
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import QuestionnaireFormV3 from '@/components/ui-components/QuestionnaireFormV3/QuestionnaireFormV3.vue'
import { trackEvent } from '@/utils/analytics/events.js'
import {
  ANALYTICS_DIMENSION_QUESTION_ID,
  ANALYTICS_EVENT_VIEW_QUESTION
} from '@/constants/analytics.js'
import { mapActions, mapGetters } from 'vuex'
import useQuestionnaireForm from '@/use/useQuestionnaireForm.js'
import { toRefs } from 'vue'
import { FETCH_ELIGIBILITY_QUESTIONNAIRE } from '@/store/selectTest.js'

export default {
  name: 'EligibilityQuestionnaire',

  components: { QuestionnaireFormV3 },

  props: {
    orgId: {
      type: [String, Number],
      required: true
    },

    questionnaireData: {
      type: Object,
      required: true
    },

    eligibilityResponseUuid: {
      type: String,
      default: ''
    },

    answers: {
      type: Object,
      default: null
    },

    questionId: {
      type: String,
      default: ''
    }
  },

  setup(props) {
    const { questionnaireData } = toRefs(props)
    const { flatQuestions, isQuestionVisible } = useQuestionnaireForm({
      questionnaireData
    })

    return { flatQuestions, isQuestionVisible }
  },

  data() {
    return {
      activeQuestionIndex: 0
    }
  },

  computed: {
    ...mapGetters(['getConfig']),

    numberOfQuestions() {
      if (!this.questionnaireData) return 0

      const questions = this.questionnaireData.questionnaire_sections.reduce((sum, section) => {
        sum += section.section_questions.length
        return sum
      }, 0)

      return questions
    },

    formQuestions() {
      return this.flatQuestions().filter(this.isQuestionVisible)
    }
  },

  async created() {
    this.$emit('init', {
      numberOfQuestions: this.numberOfQuestions,
      formQuestions: this.formQuestions
    })

    trackEvent(ANALYTICS_EVENT_VIEW_QUESTION, {
      [ANALYTICS_DIMENSION_QUESTION_ID]:
        this.questionnaireData.questionnaire_sections?.[0]?.section_questions?.[0]?.question_id
    })
  },

  methods: {
    ...mapActions('selectTest', {
      fetchEligibilityQuestionnaire: FETCH_ELIGIBILITY_QUESTIONNAIRE
    }),

    back() {
      this.$refs.questionnaire?.back?.()
    },

    async getQuestionnaire() {
      await this.fetchEligibilityQuestionnaire(this.eligibilityResponseUuid)
    },

    handleNavigation({ index, question, numberOfQuestions }) {
      this.activeQuestionIndex = index

      trackEvent(ANALYTICS_EVENT_VIEW_QUESTION, {
        [ANALYTICS_DIMENSION_QUESTION_ID]: question.question_id
      })

      this.$emit('navigate', { index, question, numberOfQuestions })
    },

    async submit({ answerForm, callback }) {
      const { questionnaireresponse_questionresponseset: responseSet } = answerForm

      try {
        await IXLayerAPI.submitEligibilityQuestionnaireResponse(this.orgId, {
          uuid: this.eligibilityResponseUuid,
          eligibility_questionnaireresponse_questionresponseset: responseSet
        })

        await this.getQuestionnaire()

        callback?.()

        if (this.activeQuestionIndex + 1 === this.numberOfQuestions) {
          this.$emit('submit', {
            responseSet,
            eligibilityResponseUuid: this.eligibilityResponseUuid
          })
        }
      } catch (err) {
        this.$toasted.show(this.$t('error.default_msg'))
      } finally {
        this.$refs.questionnaire.resetSubmitInProgress()
      }
    }
  }
}
</script>
