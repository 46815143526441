<template>
  <InputDateV3
    :id="question.question_id"
    v-model="date"
    mode="year"
    :default-options="$t('form.date_of_birth.default_options')"
    :is-form-dirty="isFormDirty"
    :validation-object="validationObject"
    css-for-error="input-error"
    class="question-date-year px-0 px-md-2"
    @input="$emit('input', date)"
  />
</template>

<script>
import InputDateV3 from '@/components/ui-components/InputDate/InputDateV3.vue'

export default {
  name: 'QuestionDateYear',

  components: {
    InputDateV3
  },

  props: {
    value: {
      type: [String, Object],
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    validationObject: {
      type: Object,
      default: () => ({})
    },

    isFormDirty: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      date: {
        year: ''
      }
    }
  },

  mounted() {
    this.date.year = this.value.year
  }
}
</script>

<style lang="scss" scoped>
.question-date-year {
  :deep(.input-select-v3) {
    margin-top: 0;
  }
}
</style>
