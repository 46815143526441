<template>
  <div class="row select-test-ineligible">
    <div class="col-12 col-md-8 col-lg-6 mx-auto">
      <BaseHeading>{{ title }}</BaseHeading>

      <BaseText v-for="(paragraph, index) in text" :key="index" as="p" class="mt-2">
        {{ paragraph }}
      </BaseText>

      <div class="select-test-ineligible__buttons">
        <BaseBtn
          v-if="showBackButton"
          secondary
          class="justify-content-center flex-fill"
          @click="$emit('back')"
        >
          {{ $t('btn.go_back') }}
        </BaseBtn>
        <BaseBtn :to="{ name: 'SelectTestResources' }" class="justify-content-center flex-fill">
          {{ $t('btn.show_resources') }}
        </BaseBtn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectTestIneligible',

  props: {
    pageContent: {
      type: Object,
      required: true
    },

    contentKey: {
      type: String,
      default: 'governmentSponsored'
    },

    showBackButton: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    title() {
      return this.pageContent.ineligible?.[this.contentKey]?.title
    },

    text() {
      return this.pageContent.ineligible?.[this.contentKey]?.text || []
    }
  }
}
</script>

<style scoped lang="scss">
.select-test-ineligible {
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: $pharma-spacing-md;
    margin-block-start: $pharma-spacing-4xl;
  }
}
</style>
