<template>
  <div class="question-multiple-choice">
    <div class="question-multiple-choice__choices">
      <BaseCheckbox
        v-for="option in question.question_answerchoices"
        :key="option.answer_id"
        v-model="selected"
        :model-value="option.answer_id"
        class="mb-0"
        v-on="inputListeners"
      >
        <div v-html="option.text" />
      </BaseCheckbox>
    </div>

    <transition :name="transition">
      <div v-if="validationObject.$error && !validationObject.required" class="input-error">
        {{ $t('validation.general.required_msg') }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'QuestionMultipleChoice',

  model: {
    event: 'change'
  },

  props: {
    value: {
      type: [String, Array],
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    transition: {
      type: [String, Array],
      default: 'fade-in-top'
    },

    validationObject: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      selected: [],
      revealedQuestions: []
    }
  },

  computed: {
    selectedAnswers() {
      return this.question.question_answerchoices.filter((answer) =>
        this.selected.includes(answer.answer_id)
      )
    },

    questionsToReveal() {
      return this.selectedAnswers.reduce((ids, answer) => {
        ids = ids.concat(...answer.reveal_question_ids)
        return ids
      }, [])
    },

    failedAnswer() {
      return this.selectedAnswers.find((answer) => answer.fail_if_selected === true)
    },

    inputListeners() {
      const vm = this

      return Object.assign({}, this.$listeners, {
        change() {
          vm.$emit('change', vm.selected.length ? vm.selected : [])

          if (vm.failedAnswer) {
            vm.$emit('failed', {
              question: vm.question,
              answer: vm.failedAnswer,
              val: vm.selected
            })

            return
          }

          if (vm.questionsToReveal.length > 0) {
            vm.$emit('hide-ids', vm.revealedQuestions)
            vm.$emit('reveal-ids', vm.questionsToReveal)
            vm.revealedQuestions = vm.questionsToReveal
          } else if (vm.revealedQuestions.length > 0) {
            vm.$emit('hide-ids', vm.revealedQuestions)
            vm.revealedQuestions = []
          }
        }
      })
    }
  },

  watch: {
    value(newValue) {
      this.selected = newValue
    }
  },

  created() {
    if (this.value.length) {
      this.selected = this.value
    }
  }
}
</script>

<style lang="scss" scoped>
.question-multiple-choice {
  &__choices {
    --column-count: 4;
    --gap: 24px;

    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(max(200px, (100% - (var(--column-count) - 1) * var(--gap)) / var(--column-count)), 1fr)
    );
    gap: var(--gap);
  }
}
</style>
