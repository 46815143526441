export const CONFIG = Object.freeze({
  ACUITY_SCHEDULING_BASE_URL: 'acuitySchedulingBaseUrl',
  CALL_TO_ACTION_ITEMS: 'callToActionItems',
  CLIENT_STYLES: 'clientStyles',
  ICON_COLOR_V2: 'iconColorV2',
  COMPANY_COUNTRY_ISO_CODE: 'companyCountryIsoCode',
  COMPANY_NAME: 'companyName',
  COMPANY_ORDER_PAGE_WEB_URL: 'companyOrderPageWebUrl',
  COMPANY_PHONE: 'companyPhone',
  DTC_ORGANIZATION: 'dtcOrganization',
  DATE_TIME_FORMAT: 'dateTimeFormat',
  DARIO_HEALTH: 'darioHealth',
  DSAR_URL: 'dsarUrl',
  ELIGIBILITY_QUESTIONNAIRE_RULE: 'eligibilityQuestionnaireRule',
  ETHNICITIES: 'ethnicities',
  RACES: 'races',
  FAQ: 'faq',
  FIND_A_SPECIALIST: 'findASpecialist',
  FOOTER_EXCLUDED_STATES: 'footerExcludedStates',
  FOOTER_MENU_ITEMS_V3: 'footerMenuItemsV3',
  GENDERS: 'genders',
  GUARDIAN_RELATIONSHIPS: 'guardianRelationships',
  GMAP_KEY: 'gmapKey',
  GOOGLE_ANALYTICS_G4: 'googleAnalyticsG4',
  HIDE_ACTIVATE_KIT_BUTTON_IN_HEADER: 'hideActivateKitButtonInHeader',
  HIDE_START_HERE_BUTTON_IN_HEADER: 'hideStartHereButtonInHeader',
  IDLE_TIMEOUT_MINUTES: 'idleTimeoutMinutes',
  IS_ADDRESS_IN_PRESCREEN_PROFILE: 'isAddressInPrescreenProfile',
  KIT_REG_COLLECTION_LOCATION: 'kitRegCollectionLocation',
  LANGUAGES: 'languages',
  LINKED_IN_PARTNER_ID: 'linkedInPartnerId',
  LINKED_IN_CONVERSIONS: 'linkedInConversions',
  MAIN_HEADER_PROFILE_PAGE_MENU_ITEMS_V3: 'mainHeaderProfilePageMenuItemsV3',
  MAIN_HEADER_SIGNED_IN_MENU_ITEMS: 'mainHeaderSignedInMenuItems',
  MAIN_HEADER_SIGNED_OUT_MENU_ITEMS: 'mainHeaderSignedOutMenuItems',
  NAVBAR: 'navbar',
  PASSWORD_POLICY_MIN_LENGTH: 'passwordPolicyMinLength',
  PRODUCT_STATUS_PAGE_SIZE: 'productStatusPageSize',
  PROFILE_AGE_LIMIT_TO_NOT_HAVE_GUARDIAN: 'profileAgeLimitToNotHaveGuardian',
  RE_CAPTCHA_SITE_KEY: 'reCaptchaSiteKey',
  ROUTER_FALLBACK: 'routerFallback',
  SELECT_TEST_ENABLED_PRODUCT_SLUGS: 'selectTestEnabledProductSlugs',
  SELECT_TEST_PAGE_CONTENT: 'selectTestPageContent',
  SELECT_TEST_PAGE_V4_CONTENT: 'selectTestPageV4Content',
  SELECT_TEST_PAGE_VERSION: 'selectTestPageVersion',
  SCANDIT_LICENSE_KEY: 'scanditLicenseKey',
  SIGN_IN_NOTE: 'signInNote',
  SIGN_UP_NOTE: 'signUpNote',
  SHOW_SIGNUP_ACCESS_CODE: 'showSignupAccessCode',
  SHOW_CANCELLED_ORDERS_ON_DASHBOARD: 'showCancelledOrdersOnDashboard',
  STRIPE_PUBLISHABLE_KEY: 'stripePublishableKey',
  STYLESHEET_LINKS: 'stylesheetLinks',
  SUPPORT_EMAIL: 'supportEmail',
  SUPPORT_PHONE: 'supportPhone',
  TERMLY_WEBSITE_UUID: 'termlyWebsiteUuid',
  TOOLTIP_CONFIG: 'tooltipConfig',
  USE_SESSION_STORAGE: 'useSessionStorage',
  USE_STATE_WORKFLOW_PROPERTY: 'useStateWorkflowProperty',
  ZIP_PATTERN: 'zipPattern',
  MIN_SUPPORTED_YEAR: 'minSupportedYear',
  ACTIVATE_KIT_DESKTOP_IMAGE: 'activateKitDesktopImage',
  ACTIVATE_KIT_MOBILE_IMAGE: 'activateKitMobileImage',
  NEW_ORDER_DESKTOP_IMAGE: 'newOrderDesktopImage',
  NEW_ORDER_MOBILE_IMAGE: 'newOrderMobileImage',
  NEW_ORDER_URL: 'newOrderUrl',
  ORDER_OVERVIEW_TITLE_CONFIG: 'orderOverviewTitleConfig',
  HELP_CENTER_URL: 'helpCenterUrl',
  ORGANIZATION: 'organization',
  ORG_CONTENT_ID: 'orgContentId',
  TERMS_OF_SERVICE_DOCUMENT: 'termsOfServiceDocument',
  PRIVACY_POLICY_DOCUMENT: 'privacyPolicyDocument',
  SMS_TERMS_OF_SERVICE_DOCUMENT: 'smsTermsOfServiceDocument',
  WORKFLOW_STATE_TEXTS: 'workflowStateTexts',
  ELIGIBILITY_TEXTS: 'eligibilityCopy',
  CHECKOUT_REQUIREMENTS_EXPLANATION: 'checkoutRequirementsExplanation'
})
