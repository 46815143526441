<template>
  <BaseBadge>
    <IconBase
      :params="{
        width: 16,
        height: 16,
        viewBox: '0 0 16 16'
      }"
      fill="none"
    >
      <IxIconCheckVerified />
    </IconBase>

    <span><slot /></span>
  </BaseBadge>
</template>

<script>
import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconCheckVerified from '@/components/icons/IxIconCheckVerified.vue'

export default {
  name: 'RecommendedBadge',

  components: { IxIconCheckVerified, IconBase }
}
</script>
