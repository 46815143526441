<i18n src="../../views/Main/Prescreen/locales/lang.json"></i18n>

<template>
  <form
    class="questionnaire-form"
    :aria-label="$t('aria_label.questionaire')"
    @submit.prevent="submit"
  >
    <IxBanner v-if="formErrors.length > 0" class="ix-banner--error mb-3">
      <div v-for="(error, index) in formErrors" :key="`error-${index}`" v-html="error" />
    </IxBanner>

    <div class="row">
      <div class="col-12">
        <!-- Profile section -->
        <template v-if="hasProfileInQuestionnaire">
          <header class="mb-3">
            <BaseHeading as="h2" variant="semibold">
              {{ $t('prescreen_questionnaire.profile.title') }}
            </BaseHeading>
            <BaseText
              v-if="featureFlags.guardianConsent"
              as="p"
              v-html="
                isAuthenticated
                  ? $t('prescreen_questionnaire.guardian_consent.signed_in')
                  : $t('prescreen_questionnaire.guardian_consent.signed_out')
              "
            />
          </header>

          <BoxV3 class="mb-4">
            <DisabledPatientProfileEditWarning
              v-if="disablePatientEditingTheirProfile"
              class="my-2"
            />

            <BaseHeading as="h3" variant="semibold">
              {{ $t('form.profile.patient.label') }}
            </BaseHeading>

            <div class="row px-0 px-md-2">
              <!-- profile first name -->
              <BaseInput
                id="profile_first_name"
                v-model="$v.personalForm.firstName.$model"
                :validations="inputValidations.personalForm.firstName"
                autocomplete="given-name"
                :label="$t('form.first_name.label')"
                :disabled="isProfileInputDisabled('firstName')"
                css-for-error="input-error"
                :css-for-input="`ix-input-v3__input ${
                  isProfileInputDisabled('firstName') ? 'ix-input-v3__input--disabled' : ''
                }`"
                css-for-label="ix-input-v3__label"
                class="ix-input-v3 col-12 col-md-4 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />

              <!-- profile last name -->
              <BaseInput
                id="profile_last_name"
                v-model="$v.personalForm.lastName.$model"
                :validations="inputValidations.personalForm.lastName"
                autocomplete="family-name"
                :label="$t('form.last_name.label')"
                :disabled="isProfileInputDisabled('lastName')"
                css-for-error="input-error"
                :css-for-input="`ix-input-v3__input ${
                  isProfileInputDisabled('lastName') ? 'ix-input-v3__input--disabled' : ''
                }`"
                css-for-label="ix-input-v3__label"
                class="ix-input-v3 col-12 col-md-4 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />

              <!-- profile email -->
              <div class="col-12 col-md-4 col-lg-3 px-3 px-md-2">
                <BaseInput
                  id="profile_email"
                  v-model="$v.personalForm.email.$model"
                  :validations="inputValidations.personalForm.email"
                  input-type="email"
                  inputmode="email"
                  :max-length="128"
                  autocomplete="email"
                  :label="$t('form.email.label_long')"
                  :disabled="isProfileInputDisabled('email')"
                  css-for-error="input-error"
                  :css-for-input="`ix-input-v3__input ${
                    isProfileInputDisabled('email') ? 'ix-input-v3__input--disabled' : ''
                  }`"
                  css-for-label="ix-input-v3__label"
                  class="ix-input-v3"
                  transition="fade-in-top"
                />

                <!-- Custom validation for signed out prescreen flow (/en/patient) when email already exist -->
                <transition v-if="emailAlreadyExists" name="fade-in-top">
                  <div class="input-error">
                    <i18n path="validation.email.already_exist.msg" tag="span">
                      <template #link>
                        <RouterLink
                          :to="
                            $i18nRoute({
                              name: 'Login',
                              query: {
                                redirect_to: `prescreen/${$route.params.orgId}/${$route.params.productId}`
                              }
                            })
                          "
                          class="v3-anchor"
                        >
                          {{ $t('btn.here') }}
                        </RouterLink>
                      </template>
                    </i18n>
                  </div>
                </transition>
              </div>

              <!-- profile phone -->
              <BaseInput
                id="profile_phone"
                v-model="$v.personalForm.phone.$model"
                :validations="inputValidations.personalForm.phone"
                autocomplete="tel"
                inputmode="tel"
                :disabled="isProfileInputDisabled('phone')"
                :mask="phoneMaskFormat"
                :label="$t('form.phone.label')"
                :placeholder="$t('form.phone.placeholder')"
                css-for-error="input-error"
                :css-for-input="`ix-input-v3__input ${
                  isProfileInputDisabled('phone') ? 'ix-input-v3__input--disabled' : ''
                }`"
                css-for-label="ix-input-v3__label"
                class="ix-input-v3 col-12 col-md-4 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />

              <!-- profile date of birth -->
              <div class="col-12 col-md-12 col-lg-9">
                <InputDateV3
                  id="date_of_birth"
                  v-model="$v.personalForm.dateOfBirth.$model"
                  mode="date"
                  :default-options="$t('form.date_of_birth.default_options')"
                  :default-option-value="null"
                  :age-limit="ageLimit"
                  :validation-object="$v.personalForm.dateOfBirth"
                  :validations="inputValidations.personalForm.dateOfBirth"
                  :is-form-dirty="$v.personalForm.$dirty"
                  :disabled="isProfileInputDisabled('dateOfBirth')"
                  :birthday-label="true"
                  css-for-error="input-error"
                  transition="fade-in-top"
                />
              </div>

              <!-- profile gender -->
              <InputSelectV3
                id="gender"
                v-model="$v.personalForm.gender.$model"
                :label="$t('form.sex_assigned_at_birth.label')"
                :label-no-break="true"
                :tooltip="$t('form.sex_assigned_at_birth.tooltip')"
                :default-option="$t('form.general.select_an_option')"
                :default-option-value="null"
                :options-data="genders"
                :disabled="isProfileInputDisabled('gender')"
                :validations="inputValidations.personalForm.gender"
                css-for-error="input-error"
                name-by="text"
                class="ix-input-v3 col-12 col-md-4 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />

              <!-- profile ethnicity -->
              <InputSelectMultipleV3
                v-if="collectEthnicityInQuestionnaire"
                id="ethnicity"
                v-model="$v.personalForm.ethnicity.$model"
                :label="$t('form.ethnicity.label')"
                :label-no-break="true"
                :default-option="$t('form.general.select')"
                :default-option-value="null"
                :options-data="ethnicities"
                :validations="inputValidations.personalForm.ethnicity"
                css-for-error="input-error"
                name-by="text"
                class="ix-input-v3 col-12 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />
            </div>

            <BaseHeading as="h3" variant="semibold" class="mt-3">
              {{ $t('form.address.label_home') }}
            </BaseHeading>

            <BaseText v-if="!isAuthenticated" as="p" variant="large-regular" class="mt-2">
              {{ $t('form.address.description') }}
            </BaseText>

            <!-- profile address -->
            <div class="row px-0 px-md-2">
              <div class="col-12">
                <InputAddressV3
                  v-if="isAddressInPrescreenProfile"
                  id="address"
                  v-model="$v.personalForm.address.$model"
                  :disabled="isProfileInputDisabled('address')"
                  :state-default-option="$t('form.address.default_option.state')"
                  :validation-object="$v.personalForm.address"
                  :is-form-dirty="$v.personalForm.$dirty"
                  css-for-error="input-error col-12"
                  css-for-col="col-12 col-md-4 col-lg-3 px-3 px-md-2"
                  transition="fade-in-top"
                />
              </div>
            </div>
          </BoxV3>
        </template>

        <!-- guardian information -->
        <template v-if="isGuardianFormVisible">
          <header class="mb-3">
            <BaseHeading as="h2" variant="semibold">
              {{ $t('form.guardian.label') }}
            </BaseHeading>

            <BaseText as="p" variant="large-regular" class="questionnaire-form__description">
              {{ $t('form.guardian.info_required', [profileAgeLimitToNotHaveGuardian]) }}
            </BaseText>
          </header>

          <BoxV3 class="mb-4" :transparent="isStepBased" :flushed="isStepBased">
            <!-- guardian name -->
            <div class="row px-0 px-md-2">
              <!-- guardian first name -->
              <BaseInput
                id="guardian_first_name"
                v-model="$v.guardianForm.firstName.$model"
                :validations="inputValidations.guardianForm.firstName"
                autocomplete="given-name"
                :label="$t('form.first_name.label')"
                css-for-error="input-error"
                css-for-input="ix-input-v3__input"
                css-for-label="ix-input-v3__label"
                class="ix-input-v3 col-12 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />

              <!-- guardian last name -->
              <BaseInput
                id="guardian_last_name"
                v-model="$v.guardianForm.lastName.$model"
                :validations="inputValidations.guardianForm.lastName"
                autocomplete="family-name"
                :label="$t('form.last_name.label')"
                css-for-error="input-error"
                css-for-input="ix-input-v3__input"
                css-for-label="ix-input-v3__label"
                class="ix-input-v3 col-12 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />

              <!-- guardian email -->
              <BaseInput
                id="guardian_email"
                v-model="$v.guardianForm.email.$model"
                :validations="inputValidations.guardianForm.email"
                input-type="email"
                :max-length="128"
                autocomplete="email"
                :label="$t('form.email.label_long')"
                css-for-error="input-error"
                css-for-input="ix-input-v3__input"
                css-for-label="ix-input-v3__label"
                class="ix-input-v3 col-12 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />

              <!-- guardian phone -->
              <BaseInput
                id="guardian_phone"
                v-model="$v.guardianForm.phone.$model"
                :validations="inputValidations.guardianForm.phone"
                autocomplete="tel"
                inputmode="tel"
                :mask="phoneMaskFormat"
                :label="$t('form.phone.label')"
                :placeholder="$t('form.phone.placeholder')"
                css-for-error="input-error"
                css-for-input="ix-input-v3__input"
                css-for-label="ix-input-v3__label"
                class="ix-input-v3 col-12 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />

              <!-- guardian date of birth -->
              <div class="col-12 col-md-12 col-lg-9">
                <InputDateV3
                  id="guardian_date_of_birth"
                  v-model="$v.guardianForm.dateOfBirth.$model"
                  mode="date"
                  :default-options="$t('form.date_of_birth.default_options')"
                  :default-option-value="null"
                  :validation-object="$v.guardianForm.dateOfBirth"
                  :validations="inputValidations.guardianForm.dateOfBirth"
                  :is-form-dirty="$v.guardianForm.$dirty"
                  css-for-error="input-error col-12"
                  transition="fade-in-top"
                  birthday-label
                />
              </div>

              <!-- profile gender -->
              <InputSelectV3
                id="guardian_gender"
                v-model="$v.guardianForm.gender.$model"
                :label="$t('form.sex_assigned_at_birth.label')"
                :label-no-break="true"
                :tooltip="$t('form.sex_assigned_at_birth.tooltip')"
                :default-option="$t('form.general.select_an_option')"
                :default-option-value="null"
                :options-data="genders"
                :validations="inputValidations.guardianForm.gender"
                css-for-error="input-error"
                name-by="text"
                class="ix-input-v3 col-12 col-md-4 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />

              <!-- guardian relationship -->
              <InputSelectV3
                id="guardian_relationship"
                v-model="$v.guardianForm.relationship.$model"
                :label="$t('form.guardian.relationship.label')"
                :default-option="$t('form.general.select')"
                :default-option-value="null"
                :options-data="guardianRelationships"
                :validations="inputValidations.guardianForm.relationship"
                css-for-error="input-error"
                name-by="text"
                class="ix-input-v3 col-12 col-md-4 col-lg-3 px-3 px-md-2"
                transition="fade-in-top"
              />
            </div>

            <!-- guardian address -->
            <div v-if="isAddressInPrescreenProfile" class="row px-0 px-md-2">
              <BaseCheckbox
                v-if="!isAuthenticated"
                v-model="isGuardianAddressSameAsPatient"
                plain
                class="mt-4 mb-2 ml-2"
                @change="onGuardianAddressCheckboxChange"
              >
                <BaseText as="p" variant="large-regular">{{
                  $t('form.guardian.same_address_as_patient')
                }}</BaseText>
              </BaseCheckbox>

              <div v-if="isAuthenticated || !isGuardianAddressSameAsPatient" class="col-12">
                <InputAddressV3
                  id="guardian_address"
                  v-model="$v.guardianForm.address.$model"
                  :state-default-option="$t('form.address.default_option.state')"
                  :validation-object="$v.guardianForm.address"
                  :is-form-dirty="$v.guardianForm.$dirty"
                  css-for-error="input-error col-12"
                  css-for-col="col-12 col-md-4 col-lg-3 px-3 px-md-2"
                  transition="fade-in-top"
                />
              </div>
            </div>
          </BoxV3>
        </template>

        <!-- Questionnaire question sections -->
        <section v-for="(section, index) in sections" :key="`section-${index}`">
          <QuestionnaireSectionHeader
            v-if="!isStepBased"
            :section="questionnaireData.questionnaire_sections[index]"
            :as="usePharmaTheming ? 'h4' : 'h2'"
            :variant="usePharmaTheming ? 'bold' : 'semibold'"
            class="mb-4"
          />

          <!-- question -->
          <transition-group tag="div" :name="isStepBased ? '' : 'fade-in-down'">
            <template v-for="(question, questionIndex) in sortQuestions(section)">
              <component
                :is="questionComponent"
                v-if="shouldShowQuestion(questionIndex)"
                :key="question.question_id"
                class="mb-4"
              >
                <QuestionHeader
                  :question="question"
                  :variant="usePharmaTheming ? 'bold' : 'medium'"
                  :should-show-optional="!isStepBased"
                />

                <!-- text input row -->
                <QuestionText
                  v-if="question.type === 'text' || question.type === 'text_single_line'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :validations="setInputValidationsById(question)"
                  :placeholder="$t('form.general.placeholder')"
                  class="col-12 px-0"
                  :class="{ 'col-lg-9': !usePharmaTheming }"
                />

                <!-- integer or decimal row -->
                <QuestionNumber
                  v-if="question.type === 'integer' || question.type === 'decimal'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :validations="setInputValidationsById(question)"
                  :placeholder="question.placeholder || $t('form.general.placeholder')"
                  class="col-12 px-0"
                  :class="{ 'col-lg-9': !usePharmaTheming }"
                />

                <!-- single_choice row -->
                <QuestionSingleChoice
                  v-if="question.type === 'single_choice'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :validation-object="$v.form[question.question_id]"
                  :use-pharma-theming="usePharmaTheming"
                  @reveal-ids="addExtraQuestions"
                  @hide-ids="removeExtraQuestions"
                  @failed="emitFailSelected"
                />

                <!-- multiple_choice row -->
                <QuestionMultipleChoice
                  v-if="question.type === 'multiple_choice'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :validation-object="$v.form[question.question_id]"
                  @reveal-ids="addExtraQuestions"
                  @hide-ids="removeExtraQuestions"
                  @failed="emitFailSelected"
                />

                <!-- input_autocomplete row -->
                <QuestionAutocomplete
                  v-if="question.type === 'autocomplete'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :options-data="question.question_answerchoices"
                  :validations="setInputValidationsById(question)"
                  placeholder="form.general.placeholder"
                  @reveal-ids="addExtraQuestions"
                  @hide-ids="removeExtraQuestions"
                  @not-listed="(val) => (optionNotListed = val)"
                />

                <!-- full date row -->
                <QuestionDateFull
                  v-if="question.type === 'date_mmddyyyy'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :is-form-dirty="$v.form.$dirty"
                  :validation-object="$v.form[question.question_id]"
                />

                <!-- full date row NEXT (1 year in future) -->
                <QuestionDateFull
                  v-if="question.type === 'date_mmddyyyy_next'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :future-date-range="1"
                  :is-form-dirty="$v.form.$dirty"
                  :validation-object="$v.form[question.question_id]"
                />

                <!-- full date row FUTURE (50 years in future) -->
                <QuestionDateFull
                  v-if="question.type === 'date_mmddyyyy_future'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :min-year="new Date().getFullYear()"
                  :future-date-range="50"
                  :is-form-dirty="$v.form.$dirty"
                  :validation-object="$v.form[question.question_id]"
                />

                <!-- MM/DD/YYYY HH/MM MS -->
                <QuestionDateTime
                  v-if="question.type === 'date_mmddyyyyhhmm'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :is-form-dirty="$v.form.$dirty"
                  :validation-object="$v.form[question.question_id]"
                />

                <!-- date year row -->
                <QuestionDateYear
                  v-if="question.type === 'date_yyyy'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :is-form-dirty="$v.form.$dirty"
                  :validation-object="$v.form[question.question_id]"
                />

                <!-- address full row -->
                <QuestionAddressFull
                  v-if="question.type === 'address_full'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                />

                <!-- address state row -->
                <QuestionAddressState
                  v-if="question.type === 'address_state'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :states="states"
                />

                <!-- phone row -->
                <QuestionPhone
                  v-if="question.type === 'phone'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :mask="phoneMaskFormat"
                  class="col-12 px-0"
                  :class="{ 'col-lg-9': !usePharmaTheming }"
                />

                <!-- email row -->
                <QuestionEmail
                  v-if="question.type === 'email'"
                  v-model="$v.form[question.question_id].$model"
                  :question="question"
                  :validations="setInputValidationsById(question)"
                  :placeholder="$t('form.email.label_long')"
                  class="col-12 px-0"
                  :class="{ 'col-lg-9': !usePharmaTheming }"
                />
              </component>
            </template>
          </transition-group>
        </section>

        <QuestionnaireHcp
          v-if="collectHcpInQuestionnaire"
          ref="hcpInfo"
          :is-editable="canEditHcpInQuestionnaire"
          :provider="provider"
          :products="products.length ? products : [product]"
        />
      </div>
    </div>

    <!-- Submit section -->
    <div v-if="hasTerms || (hasSms && isSmsEnabled)">
      <BoxV3>
        <ConsentTerms v-if="hasTerms" v-model="terms" />
        <SmsOptIn v-if="isSmsEnabled" v-model="smsOptIn" :class="{ 'mt-3': hasTerms }" />
      </BoxV3>
      <!-- reCaptcha warning text and link in case of hiding reCaptcha badge -->
      <BaseText
        v-if="hasProfileInQuestionnaire"
        as="p"
        variant="caption-regular"
        class="mt-3 text-right"
      >
        <i18n path="form.recaptcha.full_text" tag="span">
          <template #privacy>
            <a :href="gPrivacyPolicyUrl" target="_blank" class="v3-anchor--secondary">
              {{ $t('form.recaptcha.privacy_policy') }}
            </a>
          </template>

          <template #tos>
            <a :href="gTermsOfServiceUrl" target="_blank" class="v3-anchor--secondary">
              {{ $t('form.recaptcha.terms_of_service') }}
            </a>
          </template>
        </i18n>
      </BaseText>
    </div>

    <component
      :is="userActionComponent"
      v-if="hasSubmitBtn"
      :value="allConsentsAccepted"
      :btns="userActionBtns"
    />
  </form>
</template>

<script>
import { toRefs } from 'vue'
import zipState from 'zip-state'
import Validation from '@/classes/Validation.js'
import { orderBy, isArray, has, cloneDeep } from 'lodash-es'
import common from '@/classes/common.js'
import { mapGetters } from 'vuex'
import { city, date, email, phone, state, street, zip } from '@/utils/validation.js'
import useQuestionnaireForm from '@/use/useQuestionnaireForm.js'
import { useEligibility } from '@/use/useEligibility.js'
import { CONFIG } from '@/constants/config.js'
import {
  getPhone,
  getPhoneMaskFormat,
  updateAgeModel,
  updateDateOfBirthInstance
} from '@/utils/profileUtils.js'
import {
  onGuardianAddressCheckboxChange,
  setGuardianAddressSameAsPatient
} from '@/utils/addressUtils.js'

import BoxV3 from '@/components/ui-components/Box/BoxV3.vue'
import ConsentTerms from '@/components/ui-components/Consent/ConsentTerms.vue'
import SmsOptIn from '@/components/ui-components/Consent/SmsOptIn.vue'
import InputAddressV3 from '@/components/ui-components/InputAddress/InputAddressV3.vue'
import InputDateV3 from '@/components/ui-components/InputDate/InputDateV3.vue'
import InputSelectV3 from '@/components/ui-components/InputSelect/InputSelectV3.vue'
import InputSelectMultipleV3 from '@/components/ui-components/InputSelectMultiple/InputSelectMultipleV3.vue'
import UserActionV3 from '@/components/ui-components/UserAction/UserActionV3.vue'
import IxBanner from '@/components/ui-components/IxBanner/IxBanner.vue'
import QuestionAddressFull from './QuestionAddressFull.vue'
import QuestionAddressState from './QuestionAddressState.vue'
import QuestionAutocomplete from './QuestionAutocomplete.vue'
import QuestionDateFull from './QuestionDateFull.vue'
import QuestionDateTime from './QuestionDateTime.vue'
import QuestionDateYear from './QuestionDateYear.vue'
import QuestionEmail from './QuestionEmail.vue'
import QuestionHeader from './QuestionHeader.vue'
import QuestionPhone from './QuestionPhone.vue'
import QuestionSingleChoice from './QuestionSingleChoice.vue'
import QuestionMultipleChoice from './QuestionMultipleChoice.vue'
import QuestionText from './QuestionText.vue'
import QuestionnaireHCP from './QuestionnaireHCP.vue'
import QuestionNumber from '@/components/ui-components/QuestionnaireFormV3/QuestionNumber.vue'
import DisabledPatientProfileEditWarning from '@/components/ui-components/DisabledPatientProfileEditWarning/DisabledPatientProfileEditWarning.vue'
import IXLayerAPI from '@/classes/IXLayerAPI.js'
import { errorHandler } from '@/utils/errorUtils.js'
import QuestionnaireSectionHeader from '@/components/ui-components/QuestionnaireFormV3/QuestionnaireSectionHeader.vue'
import UserActionV4 from '@/components/ui-components/UserAction/UserActionV4.vue'
import { trackEvent } from '@/utils/analytics/events.js'
import {
  ANALYTICS_DIMENSION_ANSWER,
  ANALYTICS_DIMENSION_QUESTION_ID,
  ANALYTICS_DIMENSION_STATE,
  ANALYTICS_EVENT_ANSWER_QUESTION
} from '@/constants/analytics.js'

const validation = new Validation()
const GOOGLE_PRIVACY_POLICY_URL = 'https://policies.google.com/privacy'
const GOOGLE_TERMS_OF_SERVICE_URL = 'https://policies.google.com/terms'

const DISABLED_AUTHENTICATED_PROFILE_FIELDS = [
  'firstName',
  'lastName',
  'email',
  'dateOfBirth',
  'gender'
]

export default {
  name: 'QuestionnaireFormV3',

  components: {
    QuestionnaireSectionHeader,
    DisabledPatientProfileEditWarning,
    QuestionNumber,
    BoxV3,
    ConsentTerms,
    SmsOptIn,
    InputAddressV3,
    InputDateV3,
    InputSelectV3,
    InputSelectMultipleV3,
    IxBanner,
    UserActionV3,
    QuestionAddressFull,
    QuestionAddressState,
    QuestionAutocomplete,
    QuestionDateFull,
    QuestionDateTime,
    QuestionDateYear,
    QuestionEmail,
    QuestionHeader,
    QuestionPhone,
    QuestionSingleChoice,
    QuestionMultipleChoice,
    QuestionText,
    QuestionnaireHcp: QuestionnaireHCP
  },

  props: {
    hasProfileInQuestionnaire: {
      type: Boolean,
      default: false
    },
    questionnaireData: {
      type: Object,
      default: null
    },
    eligibilityResponse: {
      type: Array,
      default: null
    },
    answers: {
      type: Object,
      default: null
    },
    questionId: {
      type: String,
      default: ''
    },
    product: {
      type: Object,
      default: null
    },
    products: {
      type: Array,
      default: () => []
    },
    isAddressInPrescreenProfile: {
      type: Boolean,
      default: false
    },
    emailAlreadyExists: {
      type: Boolean,
      default: false
    },
    invalidPhoneNumber: {
      type: String,
      default: ''
    },
    ageLimit: {
      type: Number,
      default: null
    },
    excludedGenders: {
      type: Array,
      default: () => []
    },
    failIfSelectedAnswerDetails: {
      type: Object,
      default: () => ({})
    },
    isSubmitted: {
      type: Boolean,
      default: false
    },
    hasTerms: {
      type: Boolean,
      default: false
    },
    hasSms: {
      type: Boolean,
      default: true
    },
    hasSubmitBtn: {
      type: Boolean,
      default: false
    },
    isBackButtonHidden: {
      type: Boolean,
      default: false
    },
    isStepBased: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { questionnaireData } = toRefs(props)
    const { extraQuestions, flatQuestions, isQuestionVisible, createValidationObject, answerForm } =
      useQuestionnaireForm({ questionnaireData })
    const { restoreAnswers } = useEligibility()

    return {
      extraQuestions,
      flatQuestions,
      isQuestionVisible,
      createValidationObject,
      emptyAnswerForm: answerForm,

      restoreAnswers
    }
  },
  data() {
    return {
      activeQuestionIndex: 0,
      submitInProgress: false,
      isSkipInProgress: false,
      setAsyncValidation: false,
      terms: false,
      gPrivacyPolicyUrl: GOOGLE_PRIVACY_POLICY_URL,
      gTermsOfServiceUrl: GOOGLE_TERMS_OF_SERVICE_URL,
      form: {},
      validationObject: {},
      states: common.filterStates(),
      personalForm: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        countryIsoCode: null,
        gender: null,
        race: null,
        ethnicity: null,
        dateOfBirth: {
          month: null,
          day: null,
          year: null
        },
        dateOfBirthInstance: null,
        age: null,
        address: {
          address: null,
          address2: null,
          state: null,
          city: null,
          zip: null
        },
        timezone: null
      },
      guardianForm: {
        firstName: null,
        lastName: null,
        email: null,
        address: {
          address: null,
          address2: null,
          state: null,
          city: null,
          zip: null
        },
        phone: null,
        age: null,
        dateOfBirth: {
          day: null,
          month: null,
          year: null
        },
        gender: null,
        relationship: null,
        dateOfBirthInstance: null
      },
      isGuardianAddressSameAsPatient: true,
      primaryHcp: null,
      smsOptIn: true,
      optionNotListed: false
    }
  },
  validations() {
    return {
      form: this.validationObject,
      personalForm: this.personalValidationObject,
      guardianForm: this.guardianValidationObject
    }
  },
  computed: {
    ...mapGetters(['profile', 'isAuthenticated', 'featureFlags', 'getConfig', 'isEfaxAvailable']),

    genders() {
      return this.getConfig(CONFIG.GENDERS)
    },

    ethnicities() {
      return this.getConfig(CONFIG.ETHNICITIES)
    },

    guardianRelationships() {
      return this.getConfig(CONFIG.GUARDIAN_RELATIONSHIPS)
    },

    hasPrescreenConsent() {
      return this.featureFlags?.hasPrescreenConsent
    },

    usePharmaTheming() {
      return this.featureFlags?.pharmaPatientPortal || this.isStepBased
    },

    allConsentsAccepted() {
      let accepted = true
      if (this.hasTerms) {
        accepted &&= this.terms
      }

      return accepted
    },

    provider() {
      if (!this.isAuthenticated || !this.collectHcpInQuestionnaire) return null

      return this.primaryHcp
    },

    collectHcpInQuestionnaire() {
      return this.isHcpEnabled()
    },

    hasMandatoryHCPFieldsFilled() {
      if (!this.isAuthenticated) return false

      // Nothing is filled yet
      if (!this.primaryHcp || !Object.keys(this.primaryHcp).length) {
        return false
      }

      const { first_name, last_name, fax_number, address, city, state, zip } = this.primaryHcp
      return Boolean(first_name && last_name && (fax_number || (address && city && state && zip)))
    },

    canEditHcpInQuestionnaire() {
      return Boolean(this.featureFlags.patientPortalEditPcp)
    },

    collectEthnicityInQuestionnaire() {
      return Boolean(this.product?.flowData?.collect_ethnicity_in_questionnaire)
    },

    profileAgeLimitToNotHaveGuardian() {
      return this.getConfig(CONFIG.PROFILE_AGE_LIMIT_TO_NOT_HAVE_GUARDIAN)
    },

    questionComponent() {
      return this.isStepBased ? 'div' : BoxV3
    },

    userActionComponent() {
      return this.isStepBased ? UserActionV4 : UserActionV3
    },

    userActionBtns() {
      const question = this.formQuestions[this.activeQuestionIndex]
      const hasSkipBtn = this.isStepBased && !question.required
      const hasAnswer = this.form[question.question_id]

      const btns = [
        {
          onClick: this.submit,
          title: 'btn.continue',
          icon: true,
          isDisabled:
            !this.allConsentsAccepted || this.submitInProgress || (hasSkipBtn && !hasAnswer),
          isLoading: this.submitInProgress
        }
      ]

      // In step based flow, we show skip button if the question is optional
      if (hasSkipBtn) {
        btns.push({
          onClick: this.skipQuestion,
          title: 'btn.skip',
          hasUnderline: true,
          isDisabled: this.isSkipInProgress || this.submitInProgress,
          isLoading: this.isSkipInProgress,
          isGhost: true
        })
      }

      // @TODO: Make this route independent
      if (this.$route.name !== 'DirectPrescreen' && !this.isBackButtonHidden) {
        btns.push({
          onClick: false,
          routerLinkTo: 'OrderTestStart',
          linkToParams: { orgId: this.$route.params.orgId },
          linkTitle: 'btn.back',
          isGhost: true
        })
      }

      return btns
    },

    personalValidationObject() {
      let personalForm = {
        firstName: validation.firstName,
        lastName: validation.lastName,
        email: email(),
        phone: phone(
          this.personalForm.countryIsoCode || this.getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)
        ),
        gender: validation.gender(this.excludedGenders),
        ethnicity: this.collectEthnicityInQuestionnaire ? validation.ethnicity(true) : {},
        dateOfBirth: date({ excludeDates: true }),
        dateOfBirthInstance: validation.dateInPastOrToday,
        age: validation.age(this.ageLimit)
      }
      if (this.isAddressInPrescreenProfile) {
        personalForm = {
          ...personalForm,
          address: {
            address: street(),
            address2: validation.address2,
            city: city(),
            state: state(this.product?.excludedStates),
            zip: this.setAsyncValidation ? zip() : {}
          }
        }
      }
      if (this.birthDate) {
        this.updateAgeModel('personalForm', this.personalForm.dateOfBirth)
      }
      return this.hasProfileInQuestionnaire ? personalForm : {}
    },

    guardianValidationObject() {
      let validationObject = {}
      if (this.isGuardianMandatory) {
        validationObject = {
          firstName: validation.firstName,
          lastName: validation.lastName,
          email: email(),
          phone: phone(
            this.personalForm.countryIsoCode || this.getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)
          ),
          address: {
            address: street(),
            address2: validation.address2,
            city: city(),
            state: state(),
            zip: zip()
          },
          gender: validation.gender(this.excludedGenders),
          relationship: validation.guardianRelationship,
          age: validation.age(this.profileAgeLimitToNotHaveGuardian),
          dateOfBirth: date({ excludeDates: true }),
          dateOfBirthInstance: validation.dateInPastOrToday
        }
      }
      return validationObject
    },

    isAllowedAtAge() {
      // Skip if this product has no age limit
      if (!has(this.product?.flowData, 'age_limit')) return true

      // Skip if user hasn't filled out birthdate (unauthenticated)
      if (this.personalForm?.age == null) return true

      // If so, is this person at or above the age limit?
      return this.personalForm?.age >= this.product?.flowData.age_limit
    },
    isAllowedInState() {
      // Does this product have a state exclusion?
      if (this.product?.excludedStates?.length === 0) return true

      // Has this user filled out state yet (unauthenticated)
      if (!has(this.personalForm?.address, 'state')) return true

      // If so, is the user's state in the exluded list?
      return !this.product?.excludedStates?.includes(this.personalForm?.address.state)
    },

    formQuestions() {
      return this.flatQuestions().filter(this.isQuestionVisible)
    },

    previousQuestion() {
      return this.formQuestions[this.activeQuestionIndex - 1]
    },

    nextQuestion() {
      return this.formQuestions[this.activeQuestionIndex + 1]
    },

    answerForm() {
      const personal = this.hasProfileInQuestionnaire ? cloneDeep(this.personalForm) : {}
      if (this.hasProfileInQuestionnaire && this.collectEthnicityInQuestionnaire) {
        personal.ethnicity = personal.ethnicity.map((option) => option.answer_id)
      }

      const guardian =
        this.hasProfileInQuestionnaire && this.isGuardianMandatory ? this.guardianForm : {}

      const answerForm = {
        personal,
        guardian,
        smsOptIn: this.smsOptIn,
        isHCPResultSharingConsented: this.collectHcpInQuestionnaire
          ? this.$refs.hcpInfo.isHCPResultSharingConsented()
          : false,
        questionnaireresponse_questionresponseset: []
      }

      this.formQuestions.forEach((question) => {
        const key = question.question_id
        let answerText = this.form[key]
        let answerId = null

        if (
          question.type === 'date_mmddyyyy' ||
          question.type === 'date_mmddyyyy_next' ||
          question.type === 'date_mmddyyyy_future'
        ) {
          const timeObj = this.form[key]
          answerText = this.$d(new Date(timeObj.year, timeObj.month, timeObj.day), 'short')
        }
        if (question.type === 'date_mmddyyyyhhmm') {
          const timeObj = this.form[key]
          answerText = `${this.$d(new Date(timeObj.year, timeObj.month, timeObj.day), 'short')} ${
            timeObj.hour
          }:${timeObj.min} ${timeObj.ms}`
        }
        if (question.type === 'date_yyyy') {
          answerText = `${this.form[key].year}`
        }
        if (question.type === 'address_full') {
          const filteredAnswerArray = [
            this.form[key].address,
            this.form[key].address2,
            this.form[key].city,
            this.form[key].state,
            this.form[key].zip
          ].filter(Boolean)
          answerText = this.enquote(filteredAnswerArray)
        }
        if (question.type === 'single_choice') {
          answerText =
            question.question_answerchoices.find((answer) => answer.answer_id === this.form[key])
              ?.text ?? ''
          answerId = this.form[key]
        }
        if (question.type === 'multiple_choice') {
          const formValue = isArray(this.form[key]) ? this.form[key] : []
          const answersTexts = formValue.map((choice) => {
            return (
              question.question_answerchoices.find((answer) => answer.answer_id === choice)?.text ??
              ''
            )
          })
          answerText = this.enquote(answersTexts)
          answerId = this.enquote(formValue)

          // If ID is race, check if race is updated in questionnaire and update the profile
          if (key === 'race' || key === 'ethnicity') {
            answerForm.personal[key] = formValue
          }
        }
        if (question.type === 'autocomplete') {
          answerText =
            question.question_answerchoices.find((answer) => answer.answer_id === this.form[key])
              ?.text ?? ''
          answerId = this.form[key]
        }
        answerForm.questionnaireresponse_questionresponseset.push({
          question_id: key,
          ...(answerText ? { answer_text: answerText } : {}),
          ...(answerId ? { answer_id: answerId } : {}),
          ...(question.destination_mapping
            ? { destination_mapping: question.destination_mapping }
            : {})
        })
      })

      return answerForm
    },

    formErrors() {
      const results = []
      if (!this.isAllowedAtAge) {
        results.push(this.$t('prescreen_questionnaire.form_errors.age_restriction'))
      }

      if (!this.isAllowedInState) {
        results.push(
          this.$t('prescreen_questionnaire.form_errors.state_restriction') +
            (this.profile?.organization?.help_center_url
              ? this.$t('prescreen_questionnaire.form_errors.help_message', {
                  url: this.profile?.organization?.help_center_url
                })
              : '')
        )
      }
      return results
    },
    gender() {
      if (this.personalForm?.gender) {
        return this.genders.find((gender) => this.personalForm.gender === gender.id).text
      }

      return ''
    },
    birthDate() {
      return common.displayDate(
        this.personalForm.dateOfBirth,
        this.getConfig(CONFIG.DATE_TIME_FORMAT)?.long
      )
    },
    ageLimitLowerThanNotHaveGuardian() {
      return this.ageLimit < this.profileAgeLimitToNotHaveGuardian
    },
    isGuardianMandatory() {
      return this.personalForm.dateOfBirth
        ? common.isGuardianMandatory(this.personalForm.dateOfBirth)
        : false
    },

    isGuardianFormVisible() {
      return (
        this.hasProfileInQuestionnaire &&
        this.isGuardianMandatory &&
        this.ageLimitLowerThanNotHaveGuardian
      )
    },

    sections() {
      const filteredSections = []

      if (this.questionnaireData) {
        this.questionnaireData.questionnaire_sections.forEach((section) => {
          const filteredQuestions = section.section_questions.filter((question) => {
            return !question.is_hidden || this.extraQuestions.includes(question.question_id)
          })
          filteredSections.push(this.sortQuestions(filteredQuestions))
        })
      }

      return filteredSections
    },
    profileCountry() {
      return this.profile?.country || this.getConfig(CONFIG.COMPANY_COUNTRY_ISO_CODE)
    },
    phoneMaskFormat() {
      return getPhoneMaskFormat(this.profileCountry)
    },
    inputValidations() {
      return {
        personalForm: {
          firstName: [
            {
              constraint: this.$v.$error && !this.$v.personalForm.firstName.required,
              message: 'validation.general.required_msg'
            }
          ],
          lastName: [
            {
              constraint: this.$v.$error && !this.$v.personalForm.lastName.required,
              message: 'validation.general.required_msg'
            }
          ],
          gender: [
            {
              constraint: this.$v.$error && !this.$v.personalForm.gender.required,
              message: 'validation.general.required_msg'
            },
            {
              constraint: this.$v.$error && !this.$v.personalForm.gender.notIn,
              message: this.$t('validation.gender.not_in', {
                genders: this.genders
                  .filter((gender) => !this.excludedGenders.includes(gender.id))
                  .map((gender) => this.$t(gender.text).toLowerCase())
                  .join(', ')
              })
            }
          ],
          ethnicity: [
            {
              constraint: this.$v.$error && !this.$v.personalForm.ethnicity.required,
              message: 'validation.general.required_msg'
            }
          ],
          email: [
            {
              constraint: this.$v.$error && !this.$v.personalForm.email.required,
              message: 'validation.general.required_msg'
            },
            {
              constraint: this.$v.$error && !this.$v.personalForm.email.email,
              message: 'validation.email.form_error_msg'
            }
          ],
          dateOfBirth: [
            {
              constraint: this.$v.$error && !this.$v.personalForm.dateOfBirthInstance?.maxValue,
              message: 'validation.date_of_birth.in_future_msg'
            },
            {
              constraint: this.$v.$error && !this.$v.personalForm.age?.minValue,
              message: this.$t('validation.age.required_msg', [this.ageLimit])
            }
          ],
          phone: [
            {
              constraint: this.$v.$error && !this.$v.personalForm.phone.required,
              message: 'validation.general.required_msg'
            },
            {
              constraint: this.$v.$error && !this.$v.personalForm.phone.validNumber,
              message: 'validation.phone.valid_number'
            },
            {
              constraint: Boolean(this.invalidPhoneNumber),
              message: this.invalidPhoneNumber
            }
          ]
        },
        guardianForm: {
          firstName: [
            {
              constraint: this.$v.$error && !this.$v.guardianForm.firstName?.required,
              message: 'validation.general.required_msg'
            }
          ],
          lastName: [
            {
              constraint: this.$v.$error && !this.$v.guardianForm.lastName?.required,
              message: 'validation.general.required_msg'
            }
          ],
          email: [
            {
              constraint: this.$v.$error && !this.$v.guardianForm.email?.required,
              message: 'validation.general.required_msg'
            },
            {
              constraint: this.$v.$error && !this.$v.guardianForm.email?.email,
              message: 'validation.email.form_error_msg'
            }
          ],
          phone: [
            {
              constraint: this.$v.$error && !this.$v.guardianForm.phone?.required,
              message: 'validation.general.required_msg'
            },
            {
              constraint: this.$v.$error && !this.$v.guardianForm.phone?.validNumber,
              message: 'validation.phone.valid_number'
            }
          ],
          dateOfBirth: [
            {
              constraint: this.$v.$error && !this.$v.guardianForm.dateOfBirthInstance?.maxValue,
              message: 'validation.date_of_birth.in_future_msg'
            },
            {
              constraint: this.$v.$error && !this.$v.guardianForm.age?.minValue,
              message: this.$t('validation.guardian.min_age_limit', [
                this.profileAgeLimitToNotHaveGuardian
              ])
            }
          ],
          gender: [
            {
              constraint: this.$v.$error && !this.$v.guardianForm.gender?.required,
              message: 'validation.general.required_msg'
            },
            {
              constraint: this.$v.$error && !this.$v.guardianForm.gender?.notIn,
              message: this.$t('validation.gender.not_in', {
                genders: this.genders
                  .filter((gender) => !this.excludedGenders.includes(gender.id))
                  .map((gender) => this.$t(gender.text).toLowerCase())
                  .join(', ')
              })
            }
          ],
          relationship: [
            {
              constraint: this.$v.$error && !this.$v.guardianForm.relationship?.required,
              message: 'validation.general.required_msg'
            }
          ]
        }
      }
    },

    isSmsEnabled() {
      return !this.isAuthenticated && this.featureFlags.smsNotificationsEnabled
    },

    disablePatientEditingTheirProfile() {
      return this.featureFlags.disablePatientEditingTheirProfile
    }
  },
  watch: {
    isSubmitted() {
      this.submit()
    },

    answers() {
      this.form = Object.assign({}, this.emptyAnswerForm(), this.form, this.answers, {
        activeQuestionIndex: this.activeQuestionIndex
      })
    },

    sections: {
      handler() {
        this.form = Object.assign({}, this.emptyAnswerForm(), this.form, this.answers, {
          activeQuestionIndex: this.activeQuestionIndex
        })
        this.validationObject = this.createValidationObject({
          excludeDates: true,
          isStepBased: this.isStepBased
        })

        if (this.profile?.race) {
          this.form.race = this.profile.race
        }

        if (this.profile?.ethnicity) {
          this.form.ethnicity = this.profile.ethnicity
        }

        if (!this.form.zip && this.profile?.zip && this.isStepBased) {
          this.form.zip = this.profile.zip
        }

        if (this.eligibilityResponse) {
          this.restoreAnswersFromEligibilityResponse()
        }
      },

      immediate: true
    },

    failIfSelectedAnswerDetails() {
      this.clearFailIfSelectedValue(this.failIfSelectedAnswerDetails)
    },

    'personalForm.dateOfBirth': function (newVal) {
      if (newVal) {
        this.updateAgeModel('personalForm', newVal)
        this.updateDateOfBirthInstance('personalForm', newVal)
      }
    },

    'guardianForm.dateOfBirth': function (newVal) {
      if (newVal) {
        this.updateAgeModel('guardianForm', newVal)
        this.updateDateOfBirthInstance('guardianForm', newVal)
      }
    },

    'personalForm.address': {
      handler: function () {
        if (this.isGuardianFormVisible && this.isGuardianAddressSameAsPatient) {
          this.setGuardianAddressSameAsPatient()
        }
      },
      deep: true
    },

    isGuardianFormVisible(newVal, oldVal) {
      if (newVal && !oldVal && this.isGuardianAddressSameAsPatient) {
        this.setGuardianAddressSameAsPatient()
      }

      if (!this.profile?.guardian_relationship) {
        this.guardianForm.relationship = this.guardianRelationships[0].id
      }
    },

    // Some questions cannot be visible, so the activeQuestionIndex must be corrected
    'formQuestions.length': function () {
      if (this.isStepBased && this.questionId) {
        const index = this.formQuestions.findIndex(
          (question) => question.question_id === this.questionId
        )

        if (index > -1 && this.activeQuestionIndex !== index) {
          this.activeQuestionIndex = index
          this.form.activeQuestionIndex = index
          this.handleNavigation()
        }
      }
    }
  },

  async created() {
    if (this.isAuthenticated) {
      this.isGuardianAddressSameAsPatient = false
      this.getMyProfile()

      if (this.collectHcpInQuestionnaire) {
        try {
          const response = await IXLayerAPI.getHealthCareProviders({ is_primary: true })
          if (response.data.results.length > 0) {
            this.primaryHcp = response.data.results[0]
          }
        } catch (error) {
          errorHandler({
            sentryErrMessage: 'FAILED TO FETCH PRIMARY HCP'
          })
        }
      }
    } else if (this.isGuardianFormVisible) {
      this.setGuardianAddressSameAsPatient()
    }

    if (this.isStepBased && this.questionId) {
      const index = this.formQuestions.findIndex(
        (question) => question.question_id === this.questionId
      )

      if (index > -1) {
        this.activeQuestionIndex = index
        this.form.activeQuestionIndex = index
        await this.handleNavigation()
      }
    }
  },

  methods: {
    getMyProfile() {
      if (this.profile) {
        // personal
        this.personalForm.firstName = this.profile.first_name
        this.personalForm.lastName = this.profile.last_name
        this.personalForm.email = this.profile.email
        this.personalForm.phone = getPhone(this.profile.phone, this.profile.country.toUpperCase())
        this.personalForm.gender = this.profile.gender
        this.personalForm.ethnicity = this.profile.ethnicity?.length ? this.profile.ethnicity : null
        if (this.collectEthnicityInQuestionnaire) {
          this.personalForm.ethnicity = this.personalForm?.ethnicity?.map((item) =>
            this.ethnicities.find((val) => val.answer_id === item)
          )
        }
        this.personalForm.race = this.profile.race?.length ? this.profile.race : null
        this.personalForm.dateOfBirth = !this.profile.date_of_birth
          ? {
              year: null,
              month: null,
              day: null
            }
          : common.getDateObj(this.profile.date_of_birth)
        this.personalForm.address.address = this.profile.address
        this.personalForm.address.address2 = this.profile.address_2
        this.personalForm.address.city = this.profile.city
        this.personalForm.address.state = this.profile.state
        this.personalForm.address.zip = this.profile.zip
        this.personalForm.countryIsoCode = this.profile.country.toUpperCase()
        this.personalForm.timezone = this.profile.timezone
        // guardian
        this.guardianForm.firstName = this.profile.guardian_first_name
        this.guardianForm.lastName = this.profile.guardian_last_name
        this.guardianForm.email = this.profile.guardian_email
        this.guardianForm.phone = getPhone(
          this.profile.guardian_phone,
          this.profile.country.toUpperCase()
        )
        this.guardianForm.dateOfBirth = !this.profile.guardian_date_of_birth
          ? { year: null, month: null, day: null }
          : common.getDateObj(this.profile.guardian_date_of_birth)
        this.guardianForm.gender = this.profile.guardian_gender
        this.guardianForm.relationship = this.profile.guardian_relationship
        this.guardianForm.address.address = this.profile.guardian_address
        this.guardianForm.address.address2 = this.profile.guardian_address_2
        this.guardianForm.address.city = this.profile.guardian_city
        this.guardianForm.address.state = this.profile.guardian_state
        this.guardianForm.address.zip = this.profile.guardian_zip
      }
    },

    updateAgeModel(formName, dateOfBirth) {
      this[formName].age = updateAgeModel(dateOfBirth)
    },

    updateDateOfBirthInstance(formName, dateOfBirth) {
      this[formName].dateOfBirthInstance = updateDateOfBirthInstance(dateOfBirth)
    },

    setGuardianAddressSameAsPatient() {
      setGuardianAddressSameAsPatient(this.guardianForm, this.personalForm)
    },

    onGuardianAddressCheckboxChange(val) {
      onGuardianAddressCheckboxChange(val, this.guardianForm, this.personalForm)
    },

    isHcpEnabled(
      property = 'collect_hcp_in_questionnaire',
      condition = 'hasMandatoryHCPFieldsFilled'
    ) {
      const isEnabled = (product) => Boolean(product?.flowData?.[property])

      const enabled = this.products?.length
        ? this.products.reduce((enabled, product) => enabled && isEnabled(product), 1)
        : isEnabled(this.product)
      if (!this.isAuthenticated) {
        // HCP profile is definitely not filled out yet
        // So only show the fields if these are editable
        return enabled && this.canEditHcpInQuestionnaire
      }

      // Show HCP info IF either editable OR if it is not editable, at least already filled out
      return enabled && (this.canEditHcpInQuestionnaire || condition)
    },

    sortQuestions(questions) {
      return orderBy(questions, 'display_order')
    },

    addExtraQuestions(idsToReveal) {
      this.extraQuestions = this.extraQuestions.concat(...idsToReveal)
    },

    removeExtraQuestions(idsToHide) {
      this.extraQuestions = this.extraQuestions.filter((id) => !idsToHide.includes(id))
      idsToHide.forEach((id) => {
        this.form[id] = ''
      })
    },

    shouldShowQuestion(questionIndex) {
      if (!this.isStepBased) return true

      return this.activeQuestionIndex === questionIndex
    },

    emitFailSelected({ question, answer, val }) {
      this.$emit('fail-selected', { question, answer, val })
    },

    clearFailIfSelectedValue(answerDetails) {
      if (answerDetails.question.type === 'single_choice') {
        this.form[answerDetails.question.question_id] = ''
      } else if (answerDetails.question.type === 'multiple_choice') {
        this.form[answerDetails.question.question_id] = answerDetails.val.filter(
          (answerId) => answerId !== answerDetails.answer.answer_id
        )
      }
    },

    setInputValidationsById(question) {
      const validationObject = this.$v.form[question.question_id]
      const validationParams = question.validation_params || {}

      const setupMinMaxNumberValidation = (validationArray, type, params = {}) => {
        if (type === 'integer') {
          validationArray.push({
            constraint: validationObject.$error && !validationObject.integer,
            message: 'validation.general.must_be_integer'
          })
        }

        if (type === 'decimal') {
          validationArray.push({
            constraint: validationObject.$error && !validationObject.decimal,
            message: 'validation.general.must_be_a_number'
          })
        }

        if (params.min) {
          validationArray.push({
            constraint: validationObject.$error && !validationObject.minValue,
            message: params.min_error || this.$t('validation.general.min_value', [params.min])
          })
        }

        if (params.max) {
          validationArray.push({
            constraint: validationObject.$error && !validationObject.maxValue,
            message: params.max_error || this.$t('validation.general.max_value', [params.max])
          })
        }

        return validationArray
      }

      const validations = [
        {
          constraint: validationObject.$error && !validationObject.required,
          message: 'validation.general.required_msg'
        }
      ]

      if (typeof validationObject.email !== 'undefined') {
        validations.push({
          constraint: validationObject.$error && !validationObject.email,
          message: 'validation.email.form_error_msg'
        })
      }

      if (question.type === 'autocomplete') {
        validations.push({
          constraint: this.optionNotListed,
          message: `validation.autocomplete.${question.question_id}`
        })
      }

      if (question.type === 'input') {
        validations.push({
          constraint: validationObject.$error && !validationObject.email,
          message: 'validation.email.form_error_msg'
        })
      }

      if (question.type === 'integer' || question.type === 'decimal') {
        setupMinMaxNumberValidation(validations, question.type, validationParams)
      }

      if (question.question_id === 'zip' && typeof validationObject.pattern !== 'undefined') {
        validations.push(
          {
            constraint: validationObject.$error && !validationObject.pattern,
            message: 'validation.zip.pattern'
          },
          {
            constraint: validationObject.$error && !validationObject.isValid,
            message: 'validation.zip.valid'
          }
        )
      }

      return validations
    },

    isProfileInputDisabled(formFieldName) {
      if (this.disablePatientEditingTheirProfile) {
        return true
      }
      return this.isAuthenticated
        ? DISABLED_AUTHENTICATED_PROFILE_FIELDS.includes(formFieldName)
        : false
    },

    restoreAnswersFromEligibilityResponse() {
      const fields = this.restoreAnswers(this.eligibilityResponse)

      for (const field of fields) {
        const { formField, parent, child, value } = field

        // Only restore answer if the field is empty
        if (child) {
          if (!this[formField][parent][child]) {
            this[formField][parent][child] = value
          }
        } else if (!this[formField][parent]) {
          this[formField][parent] = value
        }
      }
    },

    enquote(arr) {
      return arr.join(';')
    },

    async handleNavigation() {
      await this.$nextTick()
      common.scrollToTop()

      this.$emit('navigate', {
        index: this.activeQuestionIndex,
        question: this.formQuestions[this.activeQuestionIndex],
        numberOfQuestions: this.formQuestions.length
      })
    },

    trackAnswer() {
      const question = this.formQuestions[this.activeQuestionIndex]

      let answerId = ''
      let state = ''

      if (question) {
        const answer = this.form[question.question_id]

        // Send only predefined answers to GA. User input in text type questions might contain PII
        if (['single_choice', 'multiple_choice'].includes(question.type)) {
          answerId = Array.isArray(answer) ? answer.join(', ') : answer
        } else if (question.question_id === 'zip') {
          answerId = answer
          state = zipState(answer)
        }

        if (!question.required && !answer) {
          answerId = 'skip'
        }
      }

      trackEvent(ANALYTICS_EVENT_ANSWER_QUESTION, {
        [ANALYTICS_DIMENSION_QUESTION_ID]: question?.question_id ?? '',
        [ANALYTICS_DIMENSION_ANSWER]: answerId,
        [ANALYTICS_DIMENSION_STATE]: state
      })
    },

    back() {
      if (!this.isStepBased) return

      if (this.activeQuestionIndex > 0) {
        this.activeQuestionIndex--
        this.form.activeQuestionIndex = this.activeQuestionIndex
        this.handleNavigation()

        return this.activeQuestionIndex
      }
    },

    continue() {
      if (!this.isStepBased) return

      this.trackAnswer()

      this.activeQuestionIndex++
      this.form.activeQuestionIndex = this.activeQuestionIndex
      this.handleNavigation()
      this.$v.$reset()
      this.submitInProgress = false
      this.isSkipInProgress = false
    },

    skipQuestion() {
      this.isSkipInProgress = true
      // Reset the possible value of the skipped question
      const question = this.formQuestions[this.activeQuestionIndex]
      if (question) {
        this.$v.form[question.question_id].$model = ''
      }
      this.continue()
    },

    async submit() {
      // If submit is in progress, then just return. This is needed to avoid multiple clicks on the submit btn
      // during data collection before emitting data to the parent component submission
      if (this.submitInProgress) {
        return
      }
      this.$emit('submit-started')
      this.submitInProgress = true
      this.setAsyncValidation = true
      this.$v.$touch()
      await common.waitForAsyncValidation(this.$v)

      let isHcpInfoValid = true
      if (this.collectHcpInQuestionnaire && this.canEditHcpInQuestionnaire) {
        isHcpInfoValid = this.$refs.hcpInfo.validate()
      }

      this.updateAgeModel('personalForm', this.personalForm.dateOfBirth)

      // We have three forms inside the validation object ($v)
      // 'form' is the original questionnaireForm, and the two optional ones are the 'personalForm' and the 'guardianForm'
      if (
        this.$v.$anyError ||
        this.$v.form.$invalid ||
        !isHcpInfoValid ||
        this.formErrors.length > 0
      ) {
        common.scrollToInvalid('.input-error')
        this.submitInProgress = false
        this.$emit('submit-failed', { formErrors: this.formErrors })
      } else {
        if (this.isStepBased) {
          if (this.activeQuestionIndex < this.formQuestions.length - 1) {
            this.$emit('submit', { answerForm: this.answerForm, callback: this.continue })

            return
          }

          // Track last answer
          this.trackAnswer()
        }

        let hcpInformation = null
        if (this.collectHcpInQuestionnaire) {
          hcpInformation = this.$refs.hcpInfo.getHcpInformation()
        }

        this.$emit('submit', { answerForm: this.answerForm, hcpInformation })
      }
    },

    // Used from parent components
    resetSubmitInProgress() {
      this.submitInProgress = false
      this.isSkipInProgress = false
    }
  }
}
</script>
