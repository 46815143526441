<template>
  <BaseInput
    autocomplete="tel"
    inputmode="tel"
    :value="value"
    :mask="mask"
    :label="$t('form.phone.label')"
    :placeholder="$t('form.phone.placeholder_short')"
    css-for-error="input-error"
    css-for-input="ix-input-v3__input"
    css-for-label="ix-input-v3__label"
    class="ix-input-v3"
    transition="fade-in-top"
    @input="(val) => $emit('input', val)"
  />
</template>

<script>
export default {
  name: 'QuestionPhone',

  props: {
    value: {
      type: String,
      required: true
    },

    question: {
      type: Object,
      required: true
    },

    mask: {
      type: String,
      default: ''
    }
  }
}
</script>
