<i18n src="./locales/lang.json"></i18n>

<template>
  <div class="container">
    <div v-if="isLoading" class="d-flex justify-content-center">
      <BSpinner variant="primary" />
    </div>

    <SelectTestIneligible
      v-else-if="isIneligible"
      :page-content="selectTestPageContent"
      :content-key="isGovernmentSponsoredSelected ? 'governmentSponsored' : 'noProductsFound'"
      :show-back-button="!isGovernmentSponsoredSelected && visibleProducts?.results?.length === 0"
      @back="() => $router.back()"
    />

    <template v-else>
      <header class="select-test-results__header">
        <BaseHeading tag="h1" as="h4" variant="bold">
          {{ $t('select_test.title') }}
        </BaseHeading>
      </header>

      <BaseToggleV4
        v-if="initialInsurance === INSURANCE_OPTIONS.COMMERCIAL"
        v-model="isCommercialInsuranceSelected"
        :off-label="$t('select_test.cash_pricing')"
        off-label-min-width="98px"
        :on-label="$t('select_test.insurance_pricing')"
        on-label-min-width="135px"
        class="select-test-results__toggle"
      />

      <SelectTestProductsV4
        class="select-test-results__products"
        :products="[...visibleProducts, ...unavailableProducts]"
        :test-details="testDetails"
        :dtc-organization="dtcOrganization"
        :zip="zip"
        :is-commercial-insurance-selected="isCommercialInsuranceSelected"
        :eligibility-response-uuid="eligibilityResponseUuid"
        :has-answered-all-questions="hasAnsweredAllQuestions"
      />
      <HsaFsaInfo />

      <TelehealthSession v-if="telehealthSessionHref" :to="{ name: 'TelehealthDetailsPage' }" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { camelCase } from 'lodash-es'
import { CONFIG } from '@/constants/config.js'
import { INSURANCE_OPTIONS } from '@/constants/selectTest.js'
import SelectTestIneligible from '@/components/views/SelectTestV4/SelectTestIneligible.vue'
import SelectTestProductsV4 from '@/components/views/SelectTestV4/SelectTestProductsV4.vue'
import HsaFsaInfo from '@/components/views/SelectTestV4/HsaFsaInfo.vue'
import TelehealthSession from '@/components/views/SelectTestV4/TelehealthSession.vue'
import {
  FETCH_ELIGIBILITY_RESPONSE,
  GET_PRODUCTS_BY_ELIGIBILITY_RESPONSE,
  SET_IS_BACK_VISIBLE,
  SET_PROGRESS
} from '@/store/selectTest.js'
import { useEligibility } from '@/use/useEligibility.js'
import { errorHandler } from '@/utils/errorUtils.js'
import { STORE_NAMESPACE } from '@/constants/store.js'

export default {
  name: 'SelectTestResults',

  components: { SelectTestIneligible, TelehealthSession, HsaFsaInfo, SelectTestProductsV4 },

  props: {
    eligibilityResponseUuid: {
      type: String,
      required: true
    }
  },

  setup() {
    const { prefetchUninsuredPriceOffering } = useEligibility()

    return {
      prefetchUninsuredPriceOffering
    }
  },

  data() {
    return {
      INSURANCE_OPTIONS,
      isLoading: true,
      isCommercialInsuranceSelected: false
    }
  },

  computed: {
    ...mapGetters({
      getConfig: 'getConfig',
      orgByAccessCode: 'orgByAccessCode',
      productList: 'productList',
      testDetails: 'cmsTestDetailsContent'
    }),
    ...mapGetters(STORE_NAMESPACE.SELECT_TEST, [
      'getEligibilityResponse',
      'products',
      'uninsuredProducts'
    ]),

    dtcOrganization() {
      return this.getConfig(CONFIG.DTC_ORGANIZATION)
    },

    selectTestPageContent() {
      return this.getConfig(CONFIG.SELECT_TEST_PAGE_V4_CONTENT)
    },

    telehealthSessionHref() {
      return this.selectTestPageContent?.telehealth?.href
    },

    selectedInsurance() {
      return this.isCommercialInsuranceSelected
        ? INSURANCE_OPTIONS.COMMERCIAL
        : INSURANCE_OPTIONS.UNINSURED
    },

    responseSet() {
      return this.getEligibilityResponse(this.eligibilityResponseUuid)
    },

    zip() {
      if (!this.responseSet) return ''

      return this.responseSet?.find((question) => question.question_id === 'zip')?.answer_text || ''
    },

    initialInsurance() {
      if (!this.responseSet) return ''

      return (
        this.responseSet?.find((question) => question.question_id === 'insurance')?.answer_id || ''
      )
    },

    isGovernmentSponsoredSelected() {
      return this.initialInsurance === INSURANCE_OPTIONS.GOVERNMENT
    },

    isIneligible() {
      return this.isGovernmentSponsoredSelected || this.visibleProducts?.length === 0
    },

    hasAnsweredAllQuestions() {
      if (!this.responseSet || this.responseSet.length === 0) return false

      return this.responseSet.every((response) => response.answer_text)
    },

    visibleProducts() {
      if (!this.isCommercialInsuranceSelected && this.uninsuredProducts.length > 0) {
        return this.uninsuredProducts
      }

      return this.products
    },

    unavailableProducts() {
      const enabledProductSlugs = this.getConfig(CONFIG.SELECT_TEST_ENABLED_PRODUCT_SLUGS)?.[
        camelCase(this.selectedInsurance)
      ]
      if (!Array.isArray(enabledProductSlugs) || enabledProductSlugs.length === 0) {
        return []
      }

      const visibleProductSlugs = this.visibleProducts.map((product) => product.slug)

      return this.productList
        .filter(
          (product) =>
            !visibleProductSlugs.includes(product.slug) &&
            enabledProductSlugs.includes(product.slug)
        )
        .map((product) => ({
          ...product,
          is_unavailable: true
        }))
    }
  },

  async created() {
    this.setIsBackVisible(true)
    this.setProgress(100)

    try {
      const promises = []
      const uuid = this.eligibilityResponseUuid

      if (!this.getEligibilityResponse(uuid)) {
        promises.push(this.fetchEligibilityResponse(uuid))
      }
      promises.push(this.getProducts({ uuid }))

      await Promise.all(promises)

      // Wait for computed properties
      await this.$nextTick()

      this.isCommercialInsuranceSelected = this.initialInsurance === INSURANCE_OPTIONS.COMMERCIAL
      if (this.isCommercialInsuranceSelected) {
        // No need to await, this can run in the background
        this.prefetchOtherPriceOffering(this.responseSet)
      }
    } catch (err) {
      errorHandler({
        toastedMessage: this.$t('select_test.error_fetching_products'),
        sentryErrMessage: `FAILED DUE TO ${err.response?.data?.message || err}`
      })
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions('selectTest', {
      getProducts: GET_PRODUCTS_BY_ELIGIBILITY_RESPONSE,
      fetchEligibilityResponse: FETCH_ELIGIBILITY_RESPONSE,
      setIsBackVisible: SET_IS_BACK_VISIBLE,
      setProgress: SET_PROGRESS
    }),

    async prefetchOtherPriceOffering(responseSet) {
      const { success, data } = await this.prefetchUninsuredPriceOffering(
        responseSet,
        this.orgByAccessCode.id
      )

      if (!success) return

      try {
        await this.getProducts({ uuid: data.uuid, productsKey: INSURANCE_OPTIONS.UNINSURED })
      } catch (err) {
        errorHandler({
          toastedMessage: this.$t('select_test.error_fetching_products'),
          sentryErrMessage: `FAILED DUE TO ${err.response?.data?.message || err}`
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.select-test-results {
  &__header {
    margin-block-end: $pharma-spacing-4xl;
    color: $pharma-color-text-primary;
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  &__toggle {
    justify-self: center;
  }

  &__products {
    margin-block: $pharma-spacing-3xl;
  }
}
</style>
