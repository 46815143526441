<template>
  <InputDateV3
    :id="question.question_id"
    v-model="date"
    mode="date"
    :default-options="$t('form.date_of_birth.default_options')"
    :min-year="minYear"
    :future-date-range="futureDateRange"
    :is-form-dirty="isFormDirty"
    :validation-object="validationObject"
    :validations="validations"
    css-for-error="input-error"
    class="px-0 px-md-2"
    @input="$emit('input', date)"
  />
</template>

<script>
import InputDateV3 from '@/components/ui-components/InputDate/InputDateV3.vue'

export default {
  name: 'QuestionDateFull',

  components: {
    InputDateV3
  },

  props: {
    question: {
      type: Object,
      required: true
    },

    minYear: {
      type: [Object, Number],
      default: null
    },

    futureDateRange: {
      type: Number,
      default: 0
    },

    validationObject: {
      type: Object,
      default: () => ({})
    },

    isFormDirty: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      date: {
        month: '',
        day: '',
        year: '',
        date: ''
      }
    }
  },

  computed: {
    validations() {
      if (this.question.type !== 'date_mmddyyyy' && this.question.type !== 'date_mmddyyyy_future') {
        return []
      }

      return [
        {
          constraint:
            this.isFormDirty &&
            this.validationObject.$model.day &&
            this.validationObject.$model.month !== '' &&
            this.validationObject.$model.year &&
            this.validationObject.$invalid,
          message: this.validationObject?.date?.$invalid
            ? 'validation.date.in_past_msg'
            : 'validation.date.in_future_msg'
        }
      ]
    }
  }
}
</script>
