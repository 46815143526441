<template>
  <div
    class="action-card"
    :class="{ 'action-card--complete': isComplete, 'action-card--button': !!action.rightBtn }"
    @click="onClick"
  >
    <div class="action-card__left">
      <IconBase
        v-if="isComplete || action.icon"
        class="action-card__action-icon-wrap"
        :class="{ 'action-card__action-icon-wrap--complete': isComplete }"
        :params="{ width: '24', height: '24', viewBox: '0 0 24 24' }"
        fill="none"
      >
        <IxIconSuccessCheckmark v-if="isComplete" />
        <InlineSvg v-else-if="isAbsoluteUrl(action.icon)" :src="action.icon" />
        <component :is="getIcon(action)" v-else />
      </IconBase>

      <div>
        <BaseHeading tag="h3" as="h5" variant="medium" class="action-card__title">
          {{ action.title }}
        </BaseHeading>

        <BaseText v-if="action.description" as="div" variant="large-regular">
          {{ action.description }}
        </BaseText>
      </div>
    </div>

    <slot name="button">
      <template v-if="!isComplete">
        <BaseBtn v-if="action.rightBtn" expand-on-small @click="() => action.rightBtn.onClick()">
          {{ action.rightBtn.title }}
        </BaseBtn>
        <IconBase v-else class="action-card__right-icon-wrap" :params="icons.arrowRight.params">
          <IxIconArrowRight />
        </IconBase>
      </template>
    </slot>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'

import { isAbsoluteUrl, isExternalLink } from '@/utils/domUtils.js'

import IconBase from '@/components/ui-components/IconBase/IconBase.vue'
import IxIconArrowRight from '@/components/icons/IxIconArrowRight.vue'
import IxIconSuccessCheckmark from '@/components/icons/IxIconSuccessCheckmark.vue'

const IxIconArrowUpRightFromSquare = () =>
  import('@/components/icons/IxIconArrowUpRightFromSquare.vue')
const IxIconBoxAlt = () => import('@/components/icons/IxIconBoxAlt.vue')
const IxIconBuilding = () => import('@/components/icons/IxIconBuilding.vue')
const IxIconCircleUp = () => import('@/components/icons/IxIconCircleUp.vue')
const IxIconDocumentAlt = () => import('@/components/icons/IxIconDocumentAlt.vue')
const IxIconDownload = () => import('@/components/icons/IxIconDownloadTray.vue')
const IxIconFinger = () => import('@/components/icons/IxIconFinger.vue')
const IxIconHomeAlt = () => import('@/components/icons/IxIconHomeAlt.vue')
const IxIconPerson = () => import('@/components/icons/IxIconPerson.vue')
const IxIconStethoscopeAlt = () => import('@/components/icons/IxIconStethoscopeAlt.vue')
const IxIconSyringe = () => import('@/components/icons/IxIconSyringe.vue')

export default {
  name: 'ActionCard',

  components: {
    IconBase,
    IxIconArrowRight,
    IxIconSuccessCheckmark,
    InlineSvg
  },

  props: {
    // {title, description, icon, link, completed, rightBtn}
    action: {
      type: Object,
      required: true
    }
  },

  computed: {
    isComplete() {
      return this.action.completed
    },

    icons() {
      return {
        arrowRight: {
          params: {
            width: '24',
            height: '24',
            viewBox: '0 0 24 24',
            name: 'Arrow right'
          }
        }
      }
    }
  },

  methods: {
    onClick() {
      const { link } = this.action

      if (!link) return

      if (isExternalLink(link)) {
        window.open(link, '_blank', 'noopener, noreferrer')
        return
      }

      this.$router.push(link).catch(() => {})
    },

    isAbsoluteUrl,

    getIcon(action) {
      switch (action.icon) {
        case 'box':
          return IxIconBoxAlt

        case 'building':
          return IxIconBuilding

        case 'circle-up':
          return IxIconCircleUp

        case 'document':
          return IxIconDocumentAlt

        case 'download':
          return IxIconDownload

        case 'external':
          return IxIconArrowUpRightFromSquare

        case 'finger':
          return IxIconFinger

        case 'home':
          return IxIconHomeAlt

        case 'person':
          return IxIconPerson

        case 'stethoscope':
          return IxIconStethoscopeAlt

        case 'syringe':
          return IxIconSyringe
      }

      return null
    }
  }
}
</script>

<style lang="scss" scoped>
$action-icon-wrap-width-and-height: 48px;
$action-icon-wrap-width-and-height-desktop: 64px;

.action-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: $pharma-spacing-3xl;
  gap: $pharma-spacing-xl;
  background: var(--components-cards-card-bg, $pharma-color-white);
  border-radius: var(--components-cards-card-radius, $pharma-card-border-radius);
  border: 2px solid var(--components-cards-card-border, $pharma-color-border-primary);
  transition: all 0.3s;

  &:hover {
    background: var(--components-cards-card-bg_hover, $pharma-color-white);
    border-radius: var(--components-cards-card-radius, $pharma-card-border-radius);
    border: 2px solid var(--colors-border-border-brand, $pharma-color-bg-brand);
    cursor: pointer;
  }

  &--complete {
    &:hover {
      background: var(--components-cards-card-bg, $pharma-color-white);
      border: 2px solid var(--components-cards-card-border, $pharma-color-border-primary);
      cursor: default;
    }
  }

  &--button {
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__title {
    color: $pharma-color-text-primary;
  }

  &__left {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    flex-direction: column;
    gap: $pharma-spacing-lg;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
      gap: 16px;
    }
  }

  &__action-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: $pharma-spacing-lg;
    width: $action-icon-wrap-width-and-height;
    min-width: $action-icon-wrap-width-and-height;
    height: $action-icon-wrap-width-and-height;
    background: var(--colors-background-bg-brand, $pharma-color-bg-brand);
    border-radius: var(--radius-full, 50%);
    color: $pharma-color-text-dim3;
    border: 0;

    @include media-breakpoint-up(md) {
      padding: $pharma-spacing-xl;
      width: $action-icon-wrap-width-and-height-desktop;
      min-width: $action-icon-wrap-width-and-height-desktop;
      height: $action-icon-wrap-width-and-height-desktop;
    }

    &--complete {
      background: var(--components-cards-card-bg, $pharma-color-white);
      border: 2px solid $pharma-color-text-dim3;
    }
  }

  &__right-icon-wrap {
    flex-shrink: 0;
  }
}
</style>
