<template>
  <div class="select-card-content">
    <img v-if="option.iconUrl" :src="option.iconUrl" alt="" class="select-card-content__img" />

    <div class="select-card-content__body">
      <slot :option="option">
        <BaseText as="strong" class="select-card-content__label">{{ option.label }}</BaseText>
        <BaseText v-if="option.description">{{ option.description }}</BaseText>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectCardContent',

  props: {
    option: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.select-card-content {
  display: flex;
  align-items: flex-start;
  gap: $pharma-spacing-lg;

  &__img {
    border-radius: $pharma-border-radius-md;
    max-width: 56px;
    flex-shrink: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-weight: 500;
  }
}
</style>
